import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import React, { useMemo, useState, FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useViewport } from 'use-viewport';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import { notify } from 'common/components/Toaster/Toaster';
import ToasterModal from 'common/components/Toaster/components/ToasterModal';
import Typography from 'common/components/Typography/Typography';
import Lock from 'common/icons/Lock.icon';
import { USER_UPDATE_PASSWORD } from 'modules/account/graphql/Mutations.graphql';
import {
  USER_UPDATE_PASSWORD_MUTATION,
  USER_UPDATE_PASSWORD_VARIABLES,
  ErrorsResponses,
} from 'modules/account/types';
import { useStoreActions } from 'store/store';
import theme from 'theme/theme';
import { escToClose, getUserIdFromJWT } from 'utils/functions';
import { SPECIAL_CHARACTER_VALIDATION_PATTERN } from 'utils/validators';
import { useIntercom } from 'react-use-intercom';

const VALIDATION_SCHEMA = Yup.object().shape({
  currentPassword: Yup.string()
    .min(6, 'Password must contain 6 characters, including 1 special character')
    .matches(
      SPECIAL_CHARACTER_VALIDATION_PATTERN,
      'Password must contain 6 characters, including 1 special character',
    )
    .required('Current password field is required'),
  newPassword: Yup.string()
    .min(6, 'Password must contain 6 characters, including 1 special character')
    .matches(
      SPECIAL_CHARACTER_VALIDATION_PATTERN,
      'Password must contain 6 characters, including 1 special character',
    )
    .required('New password field is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords don’t match')
    .required('Password confirmation is required!'),
});

interface ModalProps {
  onChange: () => void;
  onTriggerReset: () => void;
}

const ChangePasswordModal: FC<ModalProps> = ({ onChange, onTriggerReset }) => {
  const navigate = useNavigate();

  const [toastId, setToastId] = useState<string>();
  const [isInvalidPassword, setInvalidPassword] = useState<boolean>(false);

  const logout = useStoreActions((state) => state.authentication.setLogout);

  const userId = getUserIdFromJWT();
  const viewport = useViewport();
  const { shutdown } = useIntercom();

  const navigateToLogin = () => navigate('/login');

  useEffect(() => {
    escToClose(onChange);
  }, []);

  const initialValues = useMemo<{
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }>(
    () => ({
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }),
    [],
  );

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const passwordInputs: {
    value: string;
    title: 'currentPassword' | 'newPassword' | 'confirmPassword';
    placeholder: string;
    label?: string;
    autocomplete: string;
  }[] = [
    {
      value: values.currentPassword,
      title: 'currentPassword',
      placeholder: 'current password',
      label: 'Enter current password',
      autocomplete: 'current-password',
    },
    {
      value: values.newPassword,
      title: 'newPassword',
      placeholder: 'enter new password',
      label: 'Enter new password',
      autocomplete: 'new-password',
    },
    {
      value: values.confirmPassword,
      title: 'confirmPassword',
      placeholder: 'confirm new password',
      autocomplete: 'new-password',
    },
  ];

  const [userUpdatePassword, { loading }] = useMutation<
    USER_UPDATE_PASSWORD_MUTATION,
    USER_UPDATE_PASSWORD_VARIABLES
  >(USER_UPDATE_PASSWORD, {
    variables: {
      userid: userId,
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    },
  });

  async function submitHandler() {
    try {
      const response = await userUpdatePassword();
      if (!response.errors) {
        if (response.data) {
          toast.dismiss(toastId);
          // Shutdown Intercom
          shutdown();
          logout();

          navigateToLogin();

          const newToastId = notify({
            autoClose: false,
            customComponent: (
              <ToasterModal
                title="Password Updated!"
                content="Please login with the new credentials below"
              />
            ),
          });
          setToastId(newToastId as string);
        }
      }
    } catch (error: any) {
      if (error.message.includes(ErrorsResponses.password)) {
        setInvalidPassword(true);
      } else {
        const toastId = notify({
          autoClose: false,
          customComponent: (
            <ToasterModal
              title="Something gone wrong!"
              content="Please try again later"
            />
          ),
        });
        setToastId(toastId as string);
      }
    }
  }

  function handleClose() {
    onChange();
  }

  return (
    <CustomComponentContainer>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <BoldText
        text="change password"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />
      <RegularText
        fontSize={viewport.width >= 576 ? 'fz18' : 'fz14'}
        text={
          'A confirmation link will be sent by email for\n security purposes.'
        }
      />
      <Spacer height={20} />
      {passwordInputs.map(
        ({ value, title, label, placeholder, autocomplete }, index) => {
          return (
            <PasswordInput
              key={`Key for password input item is - ${title} - ${index}`}>
              {label && (
                <>
                  <Spacer height={10} />
                  <Label
                    text={label}
                    fontSize={viewport.width >= 576 ? 'fz18' : 'fz14'}
                  />
                </>
              )}
              <InputContainer>
                <Icon>
                  <Lock />
                </Icon>
                <InputWithIcon
                  height={viewport.width >= 576 ? 70 : 60}
                  type="password"
                  value={value}
                  withBottomLine
                  placeholder={placeholder}
                  onResetClick={onTriggerReset}
                  onChange={handleChange(title)}
                  small={true}
                  autoComplete={autocomplete}
                  isInvalidPassword={
                    title === 'currentPassword' && isInvalidPassword
                  }
                  error={
                    Boolean(errors[title] && touched[title])
                      ? errors[title]
                      : undefined
                  }
                />
              </InputContainer>
              <Spacer height={10} />
            </PasswordInput>
          );
        },
      )}
      <Spacer height={20} />
      <SubmitButton
        width={140}
        height={40}
        borderRadius={50}
        borderColor={theme.colors.white}
        label={
          <SubmitButtonContent>
            <Typography
              text="submit"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.white}
            />
            <Typography
              text="change"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.yellow}
            />
          </SubmitButtonContent>
        }
        onClick={handleSubmit}
        isLoading={loading}
      />
    </CustomComponentContainer>
  );
};

const SubmitButton = styled(Button)`
  padding: 20px 33px;
  margin-left: 55px;
  width: auto;

  @media (min-width: 576px) {
    margin-left: 0;
  }
`;

const SubmitButtonContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  font-family: 'HKGrotesk-Black';
`;

const PasswordInput = styled.div`
  width: 100%;
  letter-spacing: -0.03em;
`;

const Label = styled(Typography)`
  padding-left: 5px;
`;

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
`;

const Icon = styled.div`
  position: absolute;
  bottom: 22px;
  left: 10px;
  transform: scale(0.9);

  @media (min-width: 576px) {
    bottom: 28px;
    transform: none;
  }
`;

const InputWithIcon = styled(TextInput)<{ size?: number }>`
  font-size: 10px;

  & > input {
    padding-left: 35px;
    margin-top: -7px;
    letter-spacing: -0.03em;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;

  @media (min-width: 576px) {
    letter-spacing: -3px;
  }
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const RegularText = styled(Typography)`
  letter-spacing: -0.03em;
  line-height: 24px;
  white-space: pre;
`;

export default ChangePasswordModal;
