import React, { useEffect } from 'react';
import { useViewport } from 'use-viewport';

import { escToClose } from 'utils/functions';

import ModalLayout from '../ModalLayout/ModalLayout';
import Typography from 'common/components/Typography/Typography';


import {
  ContentContainer,
  FooterContainer,
  IconContainer,
  RowContainer,
  Text,
} from './index.styled';
import { xs } from 'utils/constants';

import { PurchasePerksType } from 'modules/artist/types';
import { getPerkIcon } from '../SongProfileIconsSection/SongProfileIconsSection';

type BenefitsModalProps = {
  onClose: () => void;
  perks: PurchasePerksType[];
};

const InfoBenefitsModal = ({ onClose, perks }: BenefitsModalProps) => {
  const viewport = useViewport();

  useEffect(escToClose(onClose), []);

  return (
    <ModalLayout onClose={onClose} title={'info & benefits'}>
      <ContentContainer>
        {perks
          .sort((a, b) => a.orderNumber - b.orderNumber)
          .map((p, key) => {
            return (
              <RowContainer key={key}>
                <IconContainer>{getPerkIcon(p)}</IconContainer>
                <Text text={p.description.replaceAll('|', '')} />
              </RowContainer>
            );
          })}

        {viewport.width >= xs && (
          <FooterContainer>
            <Typography
              text={
                'By proceeding, I agree to SongBits Terms of Service and acknowledge that this transaction is irreversible and nonrefundable.'
              }
              fontSize={'fz10'}
            />
          </FooterContainer>
        )}
      </ContentContainer>
    </ModalLayout>
  );
};

export default InfoBenefitsModal;
