import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import { TypographyFontSizes } from 'common/common.types';
import Button from 'common/components/Button/Button';

export const ColumnContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flext-start;
  margin-top: 20px;
  width: 100%;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

export const InlineContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  line-height: 100%;
`;

export const Details = styled.div`
  width: 340px;
  @media (min-width: 576px) {
    width: 491px;
  }
`;

export const AvailableAmount = styled.div`
  font-family: 'HKGrotesk-Black';
  width: 95%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 576px) {
    width: 80%;
  }
`;

export const AvailablePayments = styled.div`
  font-family: 'HKGrotesk-Regular';
  width: 95%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 576px) {
    width: 80%;
  }
`;

export const EditCard = styled.div`
  display: flex;
  justify-items: center;
  gap: 10px;
`;

export const BorderLineWhite = styled.div`
  bottom: 0;
  height: 1px;
  margin-top: 10px;
  width: 334px;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.white};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.white},
    ${(props) => props.theme.colors.white},
    ${(props) => props.theme.colors.transparent}
  );
  @media (min-width: 576px) {
    width: 490px;
  }
`;

export const BorderLineYellow = styled.div`
  bottom: 0;
  height: 1px;
  margin-top: 10px;
  width: 334px;
  opacity: 0.5;
  background-color: ${(props) => props.theme.colors.yellow};
  background: linear-gradient(
    0.25turn,
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.yellow},
    ${(props) => props.theme.colors.transparent}
  );
  @media (min-width: 576px) {
    width: 490px;
  }
`;

export const TitleText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
`;

export const LightText = styled(Typography)`
  font-family: 'HKGrotesk-Light';
  font-weight: 200;
`;

export const SmallText = styled(Typography)<{
  fontSize?: keyof typeof TypographyFontSizes;
}>`
  font-family: 'HKGrotesk-Light';
  font-size: ${(props) =>
    props.fontSize ? props.fontSize.replace('fz', '') + 'px' : '16px'};
`;

export const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

export const PaymentRow = styled.div`
  gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

export const IconContainer = styled.div`
  width: 63px;
  display: flex;
  justify-content: center;
`;

export const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

export const NewCard = styled(Button)`
  font-family: HKGrotesk-Light;
  font-size: 18px;
  font-weight: 100;

  &:hover {
    background: transparent;
  }
`;

export const CardButtonContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 22px;
`;

export const IconButton = styled(Button)`
  &:hover {
    background: transparent;
  }
`;
