import React, { useEffect, useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useViewport } from 'use-viewport';

//import DisconnectWalletButton from 'common/components/WalletButton/DisconnectWalletButton';
import Spacer from 'common/components/Spacer/Spacer';
import PageContainer from 'common/layout/PageContainer';
import Typography from 'common/components/Typography/Typography';
import Caption from 'common/components/Caption/Caption';
import IconCaption from 'common/components/IconCaption/IconCaption';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import Visa from 'common/icons/Visa.icon';
import Clock from 'common/icons/Clock.icon';

import TextInputPlaceholder from 'common/components/Placeholder/TextInput';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';

import { BreadcrumbsContainer } from 'common/layout/styled/Breadcrumbs.styled';
import MobileBreadcrumbs from 'common/layout/MobileBreadcrumbs';
import theme from 'theme/theme';
import { getUserIdFromJWT } from 'utils/functions';
import { GET_USERS_COUNTRY } from 'modules/library/graphql/Queries.graphql';

import SBModal from 'common/components/Modal/SBModal';
import AddIncomingCryptoModal from './components/Modals/AddIncomingCryptoModal';
import CryptoIncomingModal from './components/Modals/CryptoIncomingModal';
import CardPaymentResultModal from './components/Modals/CardPaymentResultModal';
import Tip from 'common/components/Tip/Tip';
import {
  CardSchema,
  PaymentModalType,
  QuickFlowStates,
} from 'modules/payments/types';
import restService from 'services/rest.service';
import Mastercard from 'common/icons/Mastercard.icon';
import { useStoreActions } from 'store/store';
import { useNavigate } from 'react-router-dom';
import AddOutgoingCrypto from 'modules/payments/PaymentModals/AddOutgoingCrypto';
import AddCryptoFunds from 'modules/payments/PaymentModals/AddCryptoFunds';
import CardPayment from 'modules/payments/PaymentModals/CardPayment';
import AddCard from 'modules/payments/PaymentModals/AddCard';

import {
  AvailableAmount,
  AvailablePayments,
  //BorderLineWhite,
  BorderLineYellow,
  CardButtonContainer,
  Details,
  EditCard,
  IconButton,
  //LightText,
  NewCard,
  SmallText,
  //TitleText,
} from './styled/MyWallet.styled';
import { TypographyFontSizes, TypographyFontWeight } from 'common/common.types';
import Edit from 'common/icons/Edit.icon';
import Delete from 'common/icons/Delete.icon';
import RemoveCardModal from './components/Wallet/RemoveCardModal';
//import ConnectWalletButton from 'common/components/WalletButton/ConnectWalletButton';
import AddFundsOptions from 'modules/payments/PaymentModals/AddFundsOptions';
import { AddCryptoFundsStates } from './components/Modals/AddCryptoFundsModal';
import RestrictedCountry from 'modules/payments/PaymentModals/RestrictedCountry';
import PageLayout from 'common/components/PageLayout/PageLayout';

/*interface BalanceDetails {
  pendingBalance: string;
  availableBalance: string;
  settledBalance: string;
}*/

export interface CardDetails {
  cardid: string;
  nickname: string;
  last4: string;
  bin: string;
}

interface RemoveCardType {
  show: boolean;
  cardid: string;
}

interface CryptoAddressDetails {
  address: string;
  chain: string;
  currency: string;
  nickname: string;
}

const AccountSettings = () => {
  const userId = getUserIdFromJWT();
  const viewport = useViewport();
  const navigate = useNavigate();

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [showRestrictedCountryModal, setShowRestrictedCountryModal] =
    useState<boolean>(false);
  const [showAddCardModal, setShowAddCardModal] = useState<boolean>(false);
  const [showCardPaymentModal, setShowCardPaymentModal] =
    useState<boolean>(false);
  const [showCardPaymentResultModal, setShowCardPaymentResultModal] =
    useState<boolean>(false);
  const [showCryptoIncomingModal, setShowCryptoIncomingModal] =
    useState<boolean>(false);
  const [showAddIncomingCryptoModal, setShowAddIncomingCryptoModal] =
    useState<boolean>(false);
  const [showAddOutgoingCryptoModal, setShowAddOutgoingCryptoModal] =
    useState<boolean>(false);
  const [showAddFundsModal, setShowAddFundsModal] = useState<boolean>(false);
  const [paymentId, setPaymentId] = useState<string>();
  const [cardId, setCardId] = useState<string>();

  const [loadingBalance, setLoadingBalance] = useState<boolean>(true);
  const [loadingCards, setLoadingCards] = useState<boolean>(true);
  const [loadingCrypto, setLoadingCrypto] = useState<boolean>(true);

  type CryptoFundsModalProps = {
    show: boolean;
    state: AddCryptoFundsStates;
  };

  const { data: userCountry, loading: countryLoading } = useQuery(
    GET_USERS_COUNTRY,
    {
      variables: { id: userId },
      notifyOnNetworkStatusChange: false,
    },
  );

  const [showAddCryptoFundsModal, setShowAddCryptoFundsModal] =
    useState<CryptoFundsModalProps>({
      show: false,
      state: AddCryptoFundsStates.PAYMENT,
    });

  const [showRemoveCardModal, setShowRemoveCardModal] =
    useState<RemoveCardType>({
      show: false,
      cardid: '',
    });

  /*const [balanceDetails, setBalanceDetails] = useState<BalanceDetails>({
    pendingBalance: '0.00',
    availableBalance: '0.00',
    settledBalance: '0.00',
  });*/

  const [cardDetails, setCardDetails] = useState<CardDetails[]>([]);

  const [mostRecentCard, setMostRecentCard] = useState<CardSchema>({
    cardId: '',
    nickname: '',
    last4: '',
  });

  const [addressDetails, setAddressDetails] = useState<CryptoAddressDetails[]>([
    {
      address: '',
      chain: '',
      currency: '',
      nickname: '',
    },
  ]);

  const quickLinks = useMemo(
    () => [
      // {
      //   title: 'Test',
      //   link: '/test/payment',
      // },
      {
        title: 'My Collection',
        link: '/library/my-collection',
      },
      {
        title: 'Transaction History',
        link: '/account/transactions',
      },
      {
        title: 'Account Settings',
        link: '/account/settings',
      },
      {
        title: 'My Royalties',
        link: '/account/royalty',
      },
      /*{
        title: 'Payouts',
        link: '/account/payouts',
      },*/
    ],
    [],
  );

  function updateCards() {
    cardList().then((data: any) => {
      const cards: CardDetails[] = [];
      data.forEach((card: any) => {
        cards.push({
          cardid: card.cardid,
          nickname: card.nickname,
          last4: card.data.last4,
          bin: card.data.bin,
        });
        setCardDetails(cards);
      });
    });
  }

  useEffect(() => {
    /*
    balanceText().then((data) => {
      let available_balance = '0.00';

      if (data.available > 0) {
        available_balance = data.available;
      }

      setBalanceDetails({
        pendingBalance: data.pending,
        availableBalance: available_balance,
        settledBalance: data.settled,
      });
    }).finally(() => {
      setLoadingBalance(false)
    });
    */

    setLoadingBalance(false);

    cardList()
      .then((data: any) => {
        const cards: CardDetails[] = [];
        data.forEach((card: any) => {
          cards.push({
            cardid: card.cardid,
            nickname: card.nickname,
            last4: card.data.last4,
            bin: card.data.bin,
          });
          setCardDetails(cards);
        });

        const mostRecent = data[0];
        if (mostRecent) {
          setMostRecentCard({
            cardId: mostRecent.cardid,
            nickname: mostRecent.nickname,
            last4: mostRecent.data.last4,
          });
        }
      })
      .finally(() => {
        setLoadingCards(false);
      });

    addressList()
      .then((data: any) => {
        const addresses: CryptoAddressDetails[] = [];
        data.forEach((address: any) => {
          addresses.push({
            address: address.address,
            chain: address.chain,
            currency: address.currency,
            nickname: address.nickname,
          });
        });
        setAddressDetails(addresses);
      })
      .finally(() => {
        setLoadingCrypto(false);
      });
  }, []);

  // GLOBAL FUNCTIONS
  useEffect(() => {
    const w = window as any;

    w.getCostOfSelected = function () {
      return '';
    };

    w.hideAddCryptoModal = function () {
      setShowAddCryptoFundsModal({
        show: false,
        state: AddCryptoFundsStates.PAYMENT,
      });
      navigate('/account/wallet');
    };

    w.hideWalletConnectModal = function () {
      setShowAddOutgoingCryptoModal(false);
      navigate('/account/wallet');
    };

    w.onInitialWalletConnected = function () {
      setShowAddOutgoingCryptoModal(false);
      setShowAddCryptoFundsModal({
        show: true,
        state: AddCryptoFundsStates.PAYMENT,
      });
    };

    w.onPurchaseSuccess = function () {
      setShowAddCryptoFundsModal({
        show: false,
        state: AddCryptoFundsStates.PAYMENT,
      });
      navigate('/account/wallet');
    };

    w.switchToCardPayment = function () {
      setShowCardPaymentModal(true);
    };

    w.setGlobalBanner = function ({
      title,
      text,
    }: {
      title: string;
      text: string;
    }) {
      setGlobalBanner({ title: title, text: text });
    };
  }, []);

  /*
  async function balanceText() {
    const balances = await restService.getUserBalances().catch((error) => {
      setGlobalBanner({
        title: 'Failed to retrieve user balance: ',
        text: error.message,
      });
    });
    return balances;
  }
  */

  async function cardList() {
    const cards = await restService.getCardsList().catch((error) => {
      setGlobalBanner({
        title: 'Failed to retrieve card list: ',
        text: error.message,
      });
    });
    return cards;
  }

  async function addressList() {
    const addresses = await restService
      .getCryptoAddressesOutgoing()
      .catch((error) => {
        setGlobalBanner({
          title: 'Failed to retrieve address data: ',
          text: error.message,
        });
      });
    return addresses;
  }

  const WhiteCaption = ({
    text,
    tip,
    fontSize,
    fontWeight,
  }: {
    text: string;
    tip?: string;
    fontSize?: keyof typeof TypographyFontSizes;
    fontWeight?: keyof typeof TypographyFontWeight;
  }) => {
    return (
      <AvailablePayments>
        <Typography
          text={text}
          fontSize={fontSize ? fontSize : 'fz18'}
          fontWeight={fontWeight ? fontWeight : 'regular'}
          fontColor={theme.colors.white}
        />
        {tip && (
          <Tip
            width={210}
            text={
              <div>
                <Typography text={tip} fontSize={'fz14'} lineHeight={'18px'} />
              </div>
            }
          />
        )}
      </AvailablePayments>
    );
  };

  const YellowCaption = ({ text, tip }: { text: string; tip?: string }) => {
    return (
      <AvailableAmount>
        <Typography
          text={text}
          fontSize="fz18"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
        />
        {tip && (
          <Tip
            width={210}
            text={
              <Typography text={tip} fontSize={'fz14'} lineHeight={'18px'} />
            }
          />
        )}
      </AvailableAmount>
    );
  };

  const Card = ({ icon, text }: { icon: JSX.Element; text: string }) => {
    return (
      <EditCard>
        <IconCaption
          className="linked-card"
          icon={icon}
          text={text}
          fontSize="fz18"
          fontWeight="regular"
          fontColor={theme.colors.white}
        />
      </EditCard>
    );
  };

  const SmallCaption = ({
    icon,
    text,
  }: {
    icon: JSX.Element;
    text: string;
  }) => {
    return (
      <EditCard>
        <IconCaption
          icon={icon}
          text={text}
          fontSize="fz14"
          fontWeight="bold"
          fontColor={theme.colors.white}
        />
      </EditCard>
    );
  };

  const CardButtons = ({ card }: { card: string }) => {
    setCardId(card);
    return (
      <CardButtonContainer>
        <IconButton
          label={<Delete />}
          className="remove-card-button"
          onClick={() => {
            setShowRemoveCardModal({ show: true, cardid: card });
          }}
        />
        <IconButton
          label={<Edit width={19} height={19} />}
          className="edit-card-button"
          onClick={() => {
            setShowAddCardModal(true);
          }}
        />
      </CardButtonContainer>
    );
  };

  const firstColumn = (
    <>
      {loadingBalance || loadingCards || loadingCrypto || countryLoading ? (
        <>
          <Details>
            <Spacer height={20} />
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>
            <TextInputPlaceholder></TextInputPlaceholder>

            <Spacer height={viewport.width >= 576 ? 80 : 10} />
          </Details>
        </>
      ) : (
        <>
          <Details>
            <Spacer height={20} />
            {/*<TitleText text="Balances" fontColor={theme.colors.yellow} />
            <Spacer height={30} />
            <YellowCaption
              text={`Available:      $${balanceDetails.availableBalance}`}
              tip="tip text"
            />
            <Spacer height={6} />
            <Caption
              text={`Pending:         $${balanceDetails.pendingBalance}`}
            />
            <Spacer height={20} />
            <BorderLineYellow />
            <Spacer height={30} />*/}
            <YellowCaption
              text="Available Payments Methods"
              tip="Please select a default payment method and add a card ready to purchase"
            />
            <Spacer height={20} />
            <Caption text="Debit & Credit Card" />
            <Spacer height={6} />
            <SmallCaption
              icon={<Clock />}
              text=" Instant | Visa & Mastercard Only"
            />
            <Spacer height={20} />
            {cardDetails.map((card, index) => (
              <>
                <EditCard key={index}>
                  <Card
                    icon={card.bin.startsWith('4') ? <Visa /> : <Mastercard />}
                    text={`   ●●●● ●●●● ●●●● ${card.last4}`}
                  />
                  <CardButtons card={card.cardid} />
                </EditCard>
                <Spacer height={30} />
              </>
            ))}
            {cardDetails.length === 0 ? (
              <NewCard
                className="add-new-card-button"
                label="Add new card"
                onClick={() => {
                  if (
                    userCountry &&
                    userCountry.userById.countryByCountry.circleProhibited
                  ) {
                    setShowRestrictedCountryModal(true);
                  } else {
                    setShowAddCardModal(true);
                  }
                }}
              />
            ) : (
              <></>
            )}
            <Spacer height={20} />
            {/*<BorderLineWhite />*/}
            <BorderLineYellow />
            <Spacer height={30} />
            <WhiteCaption
              text="Crypto Currency"
              tip="If you wish to use crypto currency to pay for your purchases then connect your Meta Mask wallet"
            />
            <Spacer height={6} />
            {<SmallCaption icon={<Clock />} text=" Instant" />}
            <Spacer height={30} />

            {/* {addressDetails.map((address, index) => (
        <>
          <EditCard key={index}>
            <Typography text={`${address.nickname} | ${address.chain}`} />
          </EditCard>
          <Spacer height={30} />
        </>
        ))} */}

            {addressDetails.map((address, index) => (
              <>
                <WhiteCaption
                  text={'Address: ' + address.address}
                  fontWeight="light"
                  fontSize="fz14"
                />
                <Spacer key={index} height={6} />
                <WhiteCaption
                  text={'Chain: ' + address.chain}
                  fontWeight="light"
                  fontSize="fz14"
                />

                <Spacer height={6} />
                <SmallText fontSize="fz14" text="Supported coins: ETH, USDC" />
                <Spacer height={20} />
              </>
            ))}

            <Spacer height={10} />

            {/*<ConnectWalletButton />*/}

            {/*addressDetails.length > 0 ? (
              <DisconnectWalletButton />
            ) : (
              <ConnectWalletButton />
            )*/}

            <Spacer height={20} />
            {/*<BorderLineWhite />
            <Spacer height={30} />
            <WhiteCaption
              fontSize="fz18"
              fontWeight="regular"
              text="Wire Transfer"
            />
            <Spacer height={6} />
            <SmallCaption
              icon={<Clock />}
              text=" 1-3 Days | Bank fees apply | See Limits"
            />
            <Spacer height={30} />
            <LightText
              fontSize="fz18"
              fontWeight="regular"
              text="Last transfer received February 5, 2022"
              fontColor={theme.colors.white}
            />
            <Spacer height={30} />
            <LightText
              fontSize="fz18"
              fontWeight="regular"
              text="View wire Transfer details"
              fontColor={theme.colors.white}
            />
            <Spacer height={20} />
            <BorderLineYellow />
            <Spacer height={20} />
            */}
            <Spacer height={viewport.width >= 576 ? 80 : 10} />
          </Details>
        </>
      )}
    </>
  );

  return (
    <>
      <PageContainer pageTitle={'My Wallet | SongBits'}>
        <BreadcrumbsContainer>
          {viewport.width < 576 && <MobileBreadcrumbs />}
        </BreadcrumbsContainer>
        <PageLayout
          loading={
            loadingBalance || loadingCards || loadingCrypto || countryLoading
          }
          padding={
            viewport.width >= 576 ? '100px 20px 0 20px' : '20px 20px 0 20px'
          }
          title="My Wallet"
          is2ColumnLayout
          sections={[
            {
              content: firstColumn,
            },
            {
              content: (
                <div
                  style={{
                    display: 'flex',
                    gap: '25px',
                    flexDirection: 'column',
                    marginTop: '15px',
                  }}>
                  {loadingBalance ||
                  loadingCards ||
                  loadingCrypto ||
                  countryLoading ? (
                    <QuickLinksPlaceholder></QuickLinksPlaceholder>
                  ) : (
                    <>
                      {viewport.width <= 767 ? (
                        <></>
                      ) : (
                        <QuickLinks
                          addFundsTrigger={() => {
                            if (
                              userCountry &&
                              userCountry.userById.countryByCountry
                                .circleProhibited
                            ) {
                              setShowRestrictedCountryModal(true);
                            } else {
                              setShowAddFundsModal(true);
                            }
                          }}
                          addFunds={false}
                          links={quickLinks}
                          isLogout
                        />
                      )}
                    </>
                  )}
                </div>
              ),
            },
          ]}
        />

        <AddCard
          isOpen={showAddCardModal}
          type={PaymentModalType.Normal}
          fromMyWallet={true}
          cardId={cardId}
          onClose={() => {
            updateCards();
            setShowAddCardModal(false);
          }}
          onCardAdded={() => {
            updateCards();
            setShowAddCardModal(false);
            //setShowCardPaymentModal(true);
          }}
        />
        <RestrictedCountry
          isOpen={showRestrictedCountryModal}
          onClose={() => {
            setShowRestrictedCountryModal(false);
          }}
          country={
            userCountry && userCountry.userById.countryByCountry.name
              ? userCountry.userById.countryByCountry.name
              : ''
          }
        />
        <CardPayment
          isOpen={showCardPaymentModal}
          type={PaymentModalType.Normal}
          mostRecentCard={mostRecentCard}
          paymentAmount={''}
          onClose={() => {
            setShowCardPaymentModal(false);
            navigate('/account/wallet');
          }}
          onPaymentComplete={(paymentId) => {
            setShowCardPaymentModal(false);
            setPaymentId(paymentId);
            setShowCardPaymentResultModal(true);
          }}
          onPaymentFail={() => {
            setShowCardPaymentModal(false);
            navigate('/account/wallet');
          }}
          onAddCard={() => {
            setShowCardPaymentModal(false);
            setShowAddCardModal(true);
          }}
        />

        <SBModal
          isOpen={showCardPaymentResultModal}
          content={
            <CardPaymentResultModal
              uuid={paymentId}
              onChange={() => {
                setShowCardPaymentResultModal(false);
                navigate('/account/wallet');
              }}
            />
          }
        />
        <SBModal
          isOpen={showCryptoIncomingModal}
          content={
            <CryptoIncomingModal
              type={PaymentModalType.Normal}
              onChange={() => {
                setShowCryptoIncomingModal(false);
              }}
            />
          }
        />
        <SBModal
          isOpen={showAddIncomingCryptoModal}
          content={
            <AddIncomingCryptoModal
              type={PaymentModalType.Normal}
              onChange={() => {
                setShowAddIncomingCryptoModal(false);
              }}
            />
          }
        />

        <AddOutgoingCrypto isOpen={showAddOutgoingCryptoModal} />
        <AddCryptoFunds
          isOpen={showAddCryptoFundsModal.show}
          state={showAddCryptoFundsModal.state}
          onClose={() => {
            setShowAddCryptoFundsModal({
              show: false,
              state: AddCryptoFundsStates.PAYMENT,
            });
          }}
          purchaseExpired={() => {}}
          switchState={(new_state) => {
            setShowAddCryptoFundsModal({
              show: true,
              state: new_state,
            });
          }}
          onPaymentComplete={() => {
            setShowAddCryptoFundsModal({
              show: false,
              state: AddCryptoFundsStates.PAYMENT,
            });
            navigate('/account/wallet');
          }}
          onError={() => {
            setShowAddCryptoFundsModal({
              show: false,
              state: AddCryptoFundsStates.PAYMENT,
            });
            setShowCardPaymentModal(true);
          }}
          onNoAvailableCards={() => {
            setShowAddCryptoFundsModal({
              show: false,
              state: AddCryptoFundsStates.PAYMENT,
            });
            setShowAddCardModal(true);
          }}
          onCardData={() => {
            setShowAddCryptoFundsModal({
              show: false,
              state: AddCryptoFundsStates.PAYMENT,
            });
            setShowCardPaymentModal(true);
          }}
        />

        <SBModal
          isOpen={showRemoveCardModal.show}
          width="434px"
          height="280px"
          top="30%"
          content={
            <RemoveCardModal
              cardId={showRemoveCardModal.cardid}
              onClose={() => {
                setShowRemoveCardModal({
                  show: false,
                  cardid: '',
                });
              }}
              onDelete={() => {
                const cards: CardDetails[] = [];
                setCardDetails(cards);
                setShowRemoveCardModal({
                  show: false,
                  cardid: '',
                });
              }}
            />
          }
        />

        <AddFundsOptions
          isOpen={showAddFundsModal}
          type={PaymentModalType.Normal}
          purchaseExpired={() => {}}
          onClose={() => {
            setShowAddFundsModal(false);
          }}
          onUSACountryDetected={() => {
            setShowAddFundsModal(false);
            setShowAddCryptoFundsModal({
              show: true,
              state: AddCryptoFundsStates.CHECK_USA_STATE,
            });
          }}
          onNoAvailableCards={() => {
            setShowAddFundsModal(false);
            setShowAddCardModal(true);
          }}
          onWalletConnected={() => {
            setShowAddFundsModal(false);
            setShowAddCryptoFundsModal({
              show: true,
              state: AddCryptoFundsStates.PAYMENT,
            });
          }}
          onNoAvailableAddresses={() => {
            setShowAddFundsModal(false);
            setShowAddCryptoFundsModal({
              show: true,
              state: AddCryptoFundsStates.CONNECT_METAMASK,
            });
          }}
          onMetaMaskNotInstalled={() => {
            setShowAddFundsModal(false);
            setShowAddCryptoFundsModal({
              show: true,
              state: AddCryptoFundsStates.METAMASK_REQUIRED,
            });
          }}
          onBrowserNotSupported={() => {
            setShowAddFundsModal(false);
            setShowAddCryptoFundsModal({
              show: true,
              state: AddCryptoFundsStates.BROWSER_INCOMPATIBLE,
            });
          }}
          next={({ state }: { state: QuickFlowStates }) => {
            if (state === QuickFlowStates.CardAuto) {
              setShowAddFundsModal(false);
              setShowCardPaymentModal(true);
            }
            if (state === QuickFlowStates.CryptoAuto) {
              setShowAddFundsModal(false);
              setShowAddCryptoFundsModal({
                show: true,
                state: AddCryptoFundsStates.PAYMENT,
              });
            }
          }}
        />
      </PageContainer>
    </>
  );
};

export default AccountSettings;
