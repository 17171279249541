import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useViewport } from 'use-viewport';

import TextContentContainer from 'common/components/TextContentContainer/TextContentContainer';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';
import { useIntercom } from 'react-use-intercom';
import Banner from '../assets/show-me-how.jpg';
import MobileBanner from '../assets/show-me-how-mobile.jpg';

import { footerHeight, footerMobileHeight, xlg, xs } from 'utils/constants';

import {
  ButtonsContainer,
  ContentButton,
  FirstButton,
  HeadContainer,
  HeadingSection,
  Image,
  ImageContainer,
  ImageGradient,
  LeftMarginButton,
  StrapLineTypography,
  StrapLineTypographyContainer,
  TextContent,
  //BoldText,
  TextContentInnerContainer,
} from './styled/TellMeMore.styled';

const TellMeMore = () => {
  const navigate = useNavigate();
  const viewport = useViewport();

  const contentContainerHeight = useMemo(
    () =>
      viewport.width < xs ? '100%' : viewport.width >= xlg ? '450px' : '320px',
    [viewport.width],
  );
  const { showSpace } = useIntercom();
  const handleFAQ = () => showSpace('help');

  const navigateToHandler = (route: string) => {
    navigate(route);
  };

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle='Show Me How | SongBits'>
      <ImageContainer>
        <Image src={viewport.height < 576 ? MobileBanner : Banner} />
        <ImageGradient banner={viewport.width < 576 ? MobileBanner : Banner} />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <StrapLineTypography
              text={viewport.width < 576 ? 'tell me ' : 'show me '}
              fontSize="fz100"
              fontWeight="bold"
              letterSpacing="-0.05em"
            />

            <div style={{ display: 'flex' }}>
              <StrapLineTypography
                text={viewport.width < 576 ? 'more' : 'how'}
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="-0.05em"
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="0.035em"
              />
              &nbsp;&nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
                letterSpacing="0.035em"
              />
              &nbsp;&nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.white}
                letterSpacing="0.035em"
              />
            </div>
          </StrapLineTypographyContainer>

          <TextContentContainer
            height={contentContainerHeight}
            altText="We believe that anyone should be able to share in the financial success of a song.">
            <TextContent
              text="It’s true. You can now become a music mogul, and purchase a bit of a song from world class artists."
              fontSize="fz14"
              fontColor={theme.colors.white}
            />
            <TextContent
              text="But what does owning a bit of a song actually mean? Well it has four clear benefits over just listening  to a song; first you receive a share of the song’s streaming revenue. Secondly you are able to resell or gift your bit at a later date, and thirdly, purchases of bits often come with additional utility such as exclusive shows, tickets, merch or other cool stuff*"
              fontSize="fz14"
              fontColor={theme.colors.white}
            />

            <TextContent
              text={
                <TextContentInnerContainer>
                  More questions? Check our{' '}
                  <span
                    style={{ color: '#FFD600', cursor: 'pointer' }}
                    onClick={() => {
                      handleFAQ()
                    }}>
                    FAQ here
                  </span>
                  .
                </TextContentInnerContainer>
              }
              fontSize="fz14"
              fontColor={theme.colors.white}
            />
          </TextContentContainer>
        </HeadingSection>

        <ButtonsContainer>
          <FirstButton
            borderRadius={50}
            borderColor={theme.colors.white}
            opacity={0.5}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="show me"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="how"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/how');
            }}
          />
          <LeftMarginButton
            borderRadius={50}
            borderColor={theme.colors.white}
            opacity={1}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="show me"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="why"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/why');
            }}
          />
          <LeftMarginButton
            borderRadius={50}
            borderColor={theme.colors.white}
            opacity={1}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton
                  text="show me"
                  fontSize="fz16"
                  fontWeight="bold"
                />
                &nbsp;
                <ContentButton
                  text="who"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {
              navigateToHandler('/who');
            }}
          />
        </ButtonsContainer>
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(TellMeMore);
