import React, { useMemo, FC, useState, useEffect } from 'react';
import { useViewport } from 'use-viewport';
import { useFormik } from 'formik';
import * as Yup from 'yup';

//import { components, InputProps, InputActionMeta } from 'react-select';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';

import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import { CountryCodes } from 'utils/country-codes';
import { USDistricts } from 'utils/us-districts';

import restService, { PublicKey } from 'services/rest.service';
import geoService from 'services/geo.service';
import Encryption from 'utils/encryption';
import { useStoreActions } from 'store/store';
import { getUserIdFromJWT } from 'utils/functions';

import {
  BoldText,
  CloseButton,
  ContentButton,
  CustomComponentContainer,
  DropDown,
  DropdownContainer,
  ExpiryContainer,
  FirstButton,
  InputContainer,
  InputWithIcon,
  LightText,
  MastercardContainer,
  TextContent,
  VisaContainer,
  WhiteButton,
  ErrorContainer,
  BorderedInput,
} from './styled/AddCardModal.styled';

import {
  AddCardSchema,
  CardSchema,
  PaymentModalType,
} from 'modules/payments/types';
import { BeatLoader } from 'react-spinners';
import Visa from 'common/icons/Visa.icon';
import Mastercard from 'common/icons/Mastercard.icon';

import 'modules/account/pages/styles/AddressAutoComplete.css';
//import { menuStyle } from 'common/styles/DropdownStyling';
import { menuStyleFullWidth } from 'common/styles/DropdownStylingFullWidth';
//import { monthOptions, yearOptions } from 'common/dropdown-values/card-options';
import { ColumnContainer, InlineContainer } from '../../styled/MyWallet.styled';
import { useQuery } from '@apollo/client';
import {
  GET_USER_BY_ID_QUERY,
  GET_USER_BY_ID_VARIABLES,
} from 'modules/account/types';
import { GET_USER_BY_ID } from 'modules/account/graphql/Queries.graphql';
import { CanadianDistricts } from 'utils/canadian-districts';

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth() + 1;

const VALIDATION_SCHEMA = Yup.object().shape({
  cardNickname: Yup.string().required('Card nickname is required'),
  nameOnCard: Yup.string().required('Name on card is required'),
  cardNumber: Yup.string().required('Card number is required'),
  expiryMonth: Yup.number()
    .integer()
    .required('Expiry month is required')
    .min(1, 'Expiry month must be between 1 and 12')
    .max(12, 'Expiry month must be between 1 and 12'),
  expiryYear: Yup.number()
    .integer()
    .required('Expiry year is required')
    .test({
      name: 'is-valid-exp-year',
      test(value, ctx) {
        if (
          value &&
          value.toString().length != 2 &&
          value.toString().length != 4
        ) {
          return ctx.createError({
            message: 'Expiry year must be either 2 or 4 digits',
          });
        }
        if (value && value.toString().length === 2) {
          const lowerLimit = Number(currentYear.toString().substring(2));
          const upperLimit = Number((currentYear + 10).toString().substring(2));
          if (value >= lowerLimit && value <= upperLimit) {
            return true;
          } else {
            return ctx.createError({
              message: `Expiry year must be between ${lowerLimit} and ${upperLimit}`,
            });
          }
        }
        if (value && value.toString().length === 4) {
          const lowerLimit = currentYear;
          const upperLimit = currentYear + 10;
          if (value >= lowerLimit && value <= upperLimit) {
            return true;
          } else {
            return ctx.createError({
              message: `Expiry year must be between ${lowerLimit} and ${upperLimit}`,
            });
          }
        }
        return true;
      },
    }),
  cvv: Yup.string()
    .matches(/^\d+$/, 'CVV must be numbers.')
    .required('CVV is required'),
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email is required'),
  //firstName: Yup.string().required('First Name field is required'),
  //lastName: Yup.string().required('Last Name field is required'),
  addressSearch: Yup.string().required('Address is required'),
  line1: Yup.string().required('Address Line 1 is required'),
  line2: Yup.string(),
  city: Yup.string().required('City is required'),
  district: Yup.string(),
  zipCode: Yup.string().required('Zip Code is required'),
  country: Yup.string().required('Country is required'),
});

interface AddCardStatus {
  status: 'active' | 'failed' | 'reset';
}

interface ModalProps {
  onChange: () => void;
  onCardAdded: (card: CardSchema) => void;
  changePaymentMethod?: () => void;
  type: PaymentModalType;
  cardId?: string; // Used when adding card from MyWallet to delete previous card
  fromMyWallet?: boolean;
}

type OptionType = {
  value: string;
  label: string;
};

/*const CustomExpiryMonthInput = (inputProps: InputProps) => {
  return <components.Input {...inputProps} autoComplete="cc-exp-month" />;
};

const CustomExpiryYearInput = (inputProps: InputProps) => {
  return <components.Input {...inputProps} autoComplete="cc-exp-year" />;
};*/

const AddCardModal: FC<ModalProps> = ({
  onChange,
  onCardAdded,
  changePaymentMethod,
  type,
  cardId,
  fromMyWallet = false,
}) => {
  const viewport = useViewport();
  const userId = getUserIdFromJWT();

  const setPublicKey = useStoreActions(
    (actions) => actions.authentication.setPublicKey,
  );

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  ///*const [expiryMonthMenuOpen, setExpiryMonthMenuOpen] =useState<boolean>(false);
  //const [expiryYearMenuOpen, setExpiryYearMenuOpen] = useState<boolean>(false);
  const [showDistrictField, setShowDistrictField] = useState<boolean>(false);
  const [showDistrictError, setDistrictError] = useState<boolean>(false);
  const [showExpiryDatePastError, setShowExpiryDatePastError] =
    useState<string>('');
  const [addressSelected, setAddressSelected] = useState<boolean>(false);
  const [noLocationFromIP, setNoLocationFromIP] = useState<boolean>(false);
  const [isAwaitingCardConfirmation, setIsAwaitingCardConfirmation] =
    useState<boolean>(false);
  const [addCardComplete, setAddCardComplete] = useState<AddCardStatus>();
  const [newCard, setNewCard] = useState<CardSchema>({
    cardId: '',
    nickname: '',
    last4: '',
  });
  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);

  const { data: accountData } = useQuery<
    GET_USER_BY_ID_QUERY,
    GET_USER_BY_ID_VARIABLES
  >(GET_USER_BY_ID, {
    variables: { userId },
  });

  useEffect(() => {
    restService
      .getPublicKey()
      .then((response) => {
        setPublicKey(response as PublicKey);
      })
      .catch((error) => {
        setGlobalBanner({
          title: 'Error:',
          text: error.message,
        });
      });

    //Get user country
    geoService
      .getUserIP()
      .then((userIP) => {
        if (userIP) {
          geoService
            .getUserCountry(userIP)
            .then((countryResponse) => {
              if (countryResponse) {
                setFieldValue('country', countryResponse.country);

                if (
                  countryResponse.country === 'US' ||
                  countryResponse.country === 'CA'
                ) {
                  setShowDistrictField(true);
                } else {
                  setShowDistrictField(false);
                }
              }
            })
            .catch(() => {
              onNoLocationFromIP();
            });
        } else {
          onNoLocationFromIP();
        }
      })
      .catch(() => {
        onNoLocationFromIP();
      });

    //
    const reactModal = Array.from(
      window.document.getElementsByClassName('ReactModal__Content '),
    )[0];
    reactModal.addEventListener('scroll', () => {
      const autocompleteHTMLElements = Array.from(
        window.document.getElementsByClassName('pac-container'),
      );
      autocompleteHTMLElements.forEach((element) => {
        element.setAttribute('style', 'display: none;');
      });
    });
  }, []);

  useEffect(() => {
    setFieldValue(
      'email',
      accountData?.userById ? accountData?.userById.email : '',
    );
  }, [accountData]);

  const initialValues = useMemo(
    () => ({
      addressSearch: '',
      cardNickname: '',
      nameOnCard: '',
      cardNumber: '',
      //expiry: '',
      cvv: '',
      email: '',
      //firstName: '',
      //lastName: '',
      line1: '',
      line2: '',
      city: '',
      district: '',
      zipCode: '',
      country: '',
      expiryMonth: '',
      expiryYear: '',
    }),
    [],
  );

  const {
    values,
    handleChange,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  async function submitHandler() {
    setShowValidationErrors(false);
    setShowExpiryDatePastError('');
    setDistrictError(false);

    if (errors) {
      const modal = document.getElementById('SBModal');
      if (modal) {
        modal.scrollTop = 0;
      }
    }

    if (values.country === 'US' || values.country === 'CA') {
      if (!values.district) {
        errors.district = 'District field is required';
        setDistrictError(true);
      }
    }

    if (
      values.cardNumber &&
      (values.cardNumber.startsWith('0') ||
        values.cardNumber.startsWith('1') ||
        values.cardNumber.startsWith('2') ||
        values.cardNumber.startsWith('3') ||
        values.cardNumber.startsWith('6') ||
        values.cardNumber.startsWith('7') ||
        values.cardNumber.startsWith('8') ||
        values.cardNumber.startsWith('9'))
    ) {
      errors.cardNumber = 'Only Visa or Mastercard cards are supported.';
      setShowValidationErrors(true);
      return;
    }

    if (!luhn_validate(values.cardNumber.replace(/\s/g, ''))) {
      errors.cardNumber = 'Please check your card number.';
      setShowValidationErrors(true);
    }

    if (values.cardNumber.startsWith('6')) {
      errors.cardNumber = 'Discover cards are not supported.';
      setShowValidationErrors(true);
      return;
    }

    if (values.expiryYear.toString() === currentYear.toString()) {
      if (parseInt(values.expiryMonth) < currentMonth) {
        setShowExpiryDatePastError('Expiry date is in the past.');
      }
    }

    if (Object.keys(errors).length !== 0) {
      setShowValidationErrors(true);
      return;
    }

    encryptCardDetails();
  }

  function deleteCard(cardId: string) {
    restService
      .deleteCard(cardId)
      .then((res) => {
        res;
        setIsAwaitingCardConfirmation(false);
        onCardAdded({
          cardId: '',
          nickname: '',
          last4: '',
          verification_type: '',
        });
      })
      .catch((error) => {
        setGlobalBanner({
          title: 'Failed to delete card: ',
          text: error.message,
        });
      });
  }

  function handleClose() {
    onChange();
  }

  function onNoLocationFromIP() {
    setFieldValue('addressSearch', 'abc');
    setAddressSelected(true);
    setNoLocationFromIP(true);
  }

  function luhn_checksum(code: string) {
    const len = code.length;
    const parity = len % 2;
    let sum = 0;
    for (let i = len - 1; i >= 0; i--) {
      let d = parseInt(code.charAt(i));
      if (i % 2 == parity) {
        d *= 2;
      }
      if (d > 9) {
        d -= 9;
      }
      sum += d;
    }
    return sum % 10;
  }
  /* luhn_validate
   * Return true if specified code (with check digit) is valid.
   */
  function luhn_validate(fullcode: string) {
    return luhn_checksum(fullcode) == 0;
  }

  function getAddressComponentValue(
    place: google.maps.places.PlaceResult,
    types: string[],
  ) {
    const values: any = {};
    for (const type of types) {
      const value = place.address_components?.find((component) =>
        component.types.includes(type),
      )?.long_name;

      if (type) values[type] = value;
    }

    return values;
  }

  function handleAddressSearchSelected(place: google.maps.places.PlaceResult) {
    if (place.address_components) {
      //Set address
      const cityTypes = [
        'postal_town',
        'locality',
        'administrative_area_level_3',
      ];
      const types = ['street_number', 'route', 'postal_code', ...cityTypes];

      const addressComponents = getAddressComponentValue(place, types);

      let address = addressComponents['street_number'];
      address = address
        ? address + ' ' + addressComponents['route']
        : addressComponents['route'];
      setFieldValue('line1', address);

      //Set city according to address
      let city = '';
      for (const cityType of cityTypes) {
        if (typeof addressComponents[cityType] !== 'undefined') {
          city = addressComponents[cityType];
          break;
        }
      }
      if (city) setFieldValue('city', city);

      //Set postal code accrding to address
      const postalCode = addressComponents['postal_code'];
      if (postalCode) setFieldValue('zipCode', postalCode);

      //setFieldValue('addressSearch', '');
      setAddressSelected(true);
    }
  }

  function encryptCardDetails() {
    // If Expiry Year is 2 digits, prepend with 20 e.g. 24 -> 2024
    let newExpiryYear = values.expiryYear;
    if (values.expiryYear.toString().length == 2) {
      newExpiryYear =
        currentYear.toString().substring(0, 2) + values.expiryYear.toString();
    }

    const cardDetails = {
      card_name: values.cardNickname,
      card_number: values.cardNumber,
      card_expiry_month: values.expiryMonth,
      card_expiry_year: newExpiryYear,
      card_cvv: values.cvv,
    };

    // PGP Encryption
    const encryption = new Encryption(cardDetails);
    encryption
      .secure()
      .then((res) => {
        // Add Card Details
        let addCardDetails: AddCardSchema;

        if (values.country === 'US' || values.country === 'CA') {
          addCardDetails = {
            nickname: values.cardNickname,
            billingDetails: {
              name: values.nameOnCard,
              city: values.city,
              country: values.country,
              line1: values.line1,
              postalCode: values.zipCode,
              district: values.district,
            },
            metadata: {
              email: values.email,
              sessionId: 'DE6FA86F60BB47B379307F851E238617',
              ipAddress: '244.28.239.130',
            },
            keyId: res.keyId,
            encryptedData: res.encryptedMessage,
            expMonth: Number(values.expiryMonth),
            expYear: Number(newExpiryYear),
          };
        } else {
          addCardDetails = {
            nickname: values.cardNickname,
            billingDetails: {
              name: values.nameOnCard,
              city: values.city,
              country: values.country,
              line1: values.line1,
              postalCode: values.zipCode,
            },
            metadata: {
              email: values.email,
              sessionId: 'DE6FA86F60BB47B379307F851E238617',
              ipAddress: '244.28.239.130',
            },
            keyId: res.keyId,
            encryptedData: res.encryptedMessage,
            expMonth: Number(values.expiryMonth),
            expYear: Number(newExpiryYear),
          };
        }

        // Add Card
        setIsAwaitingCardConfirmation(true);
        restService
          .addCard(addCardDetails)
          .then((res) => {
            const interval = setInterval(() => {
              restService
                .getCardsList(res.cardid)
                .then((res) => {
                  if (res.status === 'active') {
                    const newCard: CardSchema = {
                      cardId: res.cardid,
                      nickname: res.nickname,
                      last4: res.data.last4,
                      verification_type: res.verification_type,
                    };

                    setNewCard(newCard);

                    if (fromMyWallet && cardId) {
                      deleteCard(cardId);
                    } else if (fromMyWallet) {
                      setIsAwaitingCardConfirmation(false);
                      onCardAdded({
                        cardId: '',
                        nickname: '',
                        last4: '',
                        verification_type: '',
                      });
                    }

                    clearInterval(interval);

                    if (fromMyWallet) {
                      (window as any).gtag("event", "wallet_card_added", {});
                    } else {
                      (window as any).gtag("event", "purchase_card_added", {});
                    }
                    onCardAdded(newCard);
                  } else if (res.status === 'failed') {
                    setAddCardComplete({ status: 'reset' });

                    if (res.data.verification.cvv == 'fail') {
                      setFieldError('cvv', 'CVV looks to be incorrect.');
                      setFieldTouched('cvv', true, false);
                    }

                    clearInterval(interval);
                    setIsAwaitingCardConfirmation(false);
                  }
                })
                .catch((error) => {
                  error;
                  setIsAwaitingCardConfirmation(false);
                  //setAddCardComplete({ status: 'failed' });
                  setAddCardComplete({ status: 'reset' });
                });
            }, 3000);
          })
          .catch((error) => {
            error;
            console.error(error);
            setIsAwaitingCardConfirmation(false);
            //setAddCardComplete({ status: 'failed' });
            setAddCardComplete({ status: 'reset' });
          });
      })
      .catch((error) => {
        error;
        //console.log('Unable to encrypt card details', error);
      });
  }

  function getCardIcon() {
    if (values.cardNumber.startsWith('4')) {
      return (
        <VisaContainer>
          <Visa width={40} height={25} />
        </VisaContainer>
      );
    } else if (values.cardNumber.startsWith('5')) {
      return (
        <MastercardContainer>
          <Mastercard width={40} height={35} />
        </MastercardContainer>
      );
    } else {
      return <></>;
    }
  }

  function loading_screen() {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: 'inherit',
          marginTop: '180px',
        }}>
        <BeatLoader color="white" loading={isAwaitingCardConfirmation} />
      </div>
    );
  }

  function card_failed_screen() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          marginTop: '160px',
        }}>
        <Typography fontColor="white" text="Failed to Add Card" />
        {type == PaymentModalType.AddFunds ? (
          <>
            <WhiteButton
              label="Try Again"
              onClick={() => {
                setAddCardComplete({ status: 'reset' });
              }}
              bgColor={theme.colors.white}
              labelColor={theme.colors.black}
              borderColor={theme.colors.white}
              borderRadius={50}
              height={45}
            />
            <WhiteButton
              label="Close"
              onClick={() => {
                handleClose();
              }}
              labelColor={theme.colors.white}
              borderColor={theme.colors.white}
              borderRadius={50}
              height={45}
            />
          </>
        ) : (
          <>
            <WhiteButton
              label="Try Again"
              onClick={() => {
                setAddCardComplete({ status: 'reset' });
              }}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.black}
              borderRadius={50}
              height={45}
            />
            <WhiteButton
              label="Change payment method"
              onClick={() => {
                if (changePaymentMethod) {
                  changePaymentMethod();
                }
              }}
              labelColor={theme.colors.yellow}
              borderColor={theme.colors.yellow}
              borderRadius={50}
              height={45}
            />
          </>
        )}
      </div>
    );
  }

  function add_card_form() {
    return (
      <>
        <TextContent
          text="Enter new card details"
          fontSize="fz18"
          fontWeight="bold"
          fontColor={theme.colors.yellow}
          letterSpacing="-0.03em"
        />
        <InputContainer>
          <InputWithIcon
            className="card-nickname-field"
            inputName="card-nickname-input"
            height={70}
            type="text"
            placeholder="Give this Card a Nickname"
            value={values.cardNickname}
            tabIndex={0}
            withBottomLine
            onChange={handleChange('cardNickname')}
            autoComplete="off"
            autoFocus={true}
            error={
              (showValidationErrors || touched.cardNickname) &&
              errors.cardNickname
                ? errors.cardNickname
                : undefined
            }
          />
        </InputContainer>
        <InputContainer>
          <InputWithIcon
            className="card-name-field"
            inputName="card-name-input"
            height={70}
            type="text"
            placeholder="Name on card"
            value={values.nameOnCard}
            tabIndex={0}
            withBottomLine
            autoComplete="cc-name"
            onChange={handleChange('nameOnCard')}
            error={
              (showValidationErrors || touched.nameOnCard) && errors.nameOnCard
                ? errors.nameOnCard
                : undefined
            }
          />
        </InputContainer>
        <InputContainer>
          <InputWithIcon
            className="card-number-field"
            inputName="card-number-input"
            height={70}
            placeholder="Card number"
            value={values.cardNumber}
            tabIndex={0}
            withBottomLine
            onChange={(inputData) => {
              let val = inputData.target.value.toString();
              const valArray = val.split(' ').join('').split('');
              const valSpace = val.split('');

              // to work with backspace
              if (valSpace[valSpace.length - 1] == ' ') {
                const valSpaceN = valSpace.slice(0, -1);
                val = valSpaceN.join('');
                setFieldValue('cardNumber', val);
                return;
              }

              if (isNaN(valArray.join('') ? valArray.join('').length : 0)) {
                return;
              }
              if (valArray.length === 17) {
                return;
              }
              if (valArray.length % 4 === 0 && valArray.length <= 15) {
                if (val.toString().length === 0) {
                  setFieldValue('cardNumber', val);
                } else {
                  setFieldValue('cardNumber', val + ' ');
                }
              } else {
                setFieldValue('cardNumber', val);
              }
            }}
            autoComplete="cc-number"
            type="text"
            inputMode="numeric"
            error={
              (showValidationErrors || touched.cardNumber) && errors.cardNumber
                ? errors.cardNumber
                : undefined
            }
          />
          {getCardIcon()}
        </InputContainer>
        <ExpiryContainer>
          <DropdownContainer>
            <LightText fontColor="white" text="Expiry" />
            <InputContainer>
              <BorderedInput
                className="card-exp-month-field"
                tabIndex={0}
                height={30}
                width={90}
                value={values.expiryMonth}
                withBorder
                borderRadius="20px"
                borderColor={theme.colors.yellow}
                autoComplete="cc-exp-month"
                maxLength={2}
                placeholder="MM"
                type="tel"
                inputMode="numeric"
                alignText={'center'}
                // onChange={handleChange('expiryMonth')}
                onChange={() => {}}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (!Number.isNaN(Number(e.target.value))) {
                    setFieldValue('expiryMonth', e.target.value);
                  }
                }}></BorderedInput>
            </InputContainer>
            <InputContainer>
              <BorderedInput
                className="card-exp-year-field"
                tabIndex={0}
                height={30}
                width={90}
                alignText={'center'}
                borderRadius="20px"
                value={values.expiryYear}
                withBorder
                maxLength={4}
                type="text"
                inputMode="numeric"
                borderColor={theme.colors.yellow}
                autoComplete="cc-exp-year"
                placeholder="YY"
                onChange={() => {}}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  if (!Number.isNaN(Number(e.target.value))) {
                    setFieldValue('expiryYear', e.target.value);
                  }
                }}></BorderedInput>
            </InputContainer>
          </DropdownContainer>
        </ExpiryContainer>

        {(showValidationErrors ||
          (touched.expiryMonth && touched.expiryYear)) &&
        errors.expiryMonth &&
        errors.expiryYear ? (
          <ErrorContainer>
            <Typography
              className="expiry-month-year-required"
              text={errors.expiryMonth + '    ' + errors.expiryYear}
              fontSize="fz12"
              fontColor={theme.colors.yellow}
            />
          </ErrorContainer>
        ) : (
          <></>
        )}
        {(showValidationErrors ||
          (touched.expiryMonth && touched.expiryYear)) &&
        !errors.expiryMonth &&
        errors.expiryYear ? (
          <ErrorContainer>
            <Typography
              className="expiry-year-error"
              text={errors.expiryYear}
              fontSize="fz12"
              fontColor={theme.colors.yellow}
            />
          </ErrorContainer>
        ) : (
          <></>
        )}
        {(showValidationErrors ||
          (touched.expiryMonth && touched.expiryYear)) &&
        errors.expiryMonth &&
        !errors.expiryYear ? (
          <ErrorContainer>
            <Typography
              className="expiry-month-error"
              text={errors.expiryMonth}
              fontSize="fz12"
              fontColor={theme.colors.yellow}
            />
          </ErrorContainer>
        ) : (
          <></>
        )}
        {showExpiryDatePastError ||
        (touched.expiryMonth && touched.expiryYear) ? (
          <ErrorContainer>
            <Typography
              text={showExpiryDatePastError}
              fontSize="fz12"
              fontColor={theme.colors.yellow}
            />
          </ErrorContainer>
        ) : (
          <></>
        )}

        <InputContainer>
          <InputWithIcon
            className="card-cvv-field"
            inputName="card-cvv-input"
            height={70}
            type="password"
            inputMode="numeric"
            placeholder="CVV"
            value={values.cvv}
            tabIndex={0}
            autoComplete="cc-csc"
            withBottomLine
            onChange={handleChange('cvv')}
            error={
              (showValidationErrors || touched.cvv) && errors.cvv
                ? errors.cvv
                : undefined
            }
          />
        </InputContainer>

        {!addressSelected && (
          <InputContainer>
            <InputWithIcon
              height={70}
              type="search"
              placeholder="Start typing your address"
              value={values.addressSearch}
              tabIndex={0}
              inputName={'addressSearch'}
              withBottomLine
              autoComplete="off"
              onChange={handleChange('addressSearch')}
              error={
                (showValidationErrors || touched.addressSearch) &&
                errors.addressSearch &&
                !addressSelected
                  ? errors.addressSearch
                  : undefined
              }
              googleAutoComplete={{
                apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
                onPlaceSelected: (place) => handleAddressSearchSelected(place),
                options: {
                  componentRestrictions: {
                    country: values.country,
                  },
                  fields: ['address_components', 'geometry', 'name'],
                  types: ['address'],
                },
              }}
            />
          </InputContainer>
        )}
        {addressSelected ? (
          <ColumnContainer>
            <InputContainer>
              <InputWithIcon
                className="address-line-1"
                inputName="address1-input"
                height={70}
                type="text"
                placeholder="Address Line 1"
                value={values.line1}
                tabIndex={0}
                withBottomLine
                onChange={handleChange('line1')}
                error={
                  (showValidationErrors || touched.line1) && errors.line1
                    ? errors.line1
                    : undefined
                }
              />
            </InputContainer>
            <InputContainer>
              <InputWithIcon
                className="address-line-2"
                inputName="address2-input"
                height={70}
                type="text"
                placeholder="Address Line 2"
                value={values.line2}
                tabIndex={0}
                onChange={handleChange('line2')}
                withBottomLine
              />
            </InputContainer>
            <InputContainer>
              <InputWithIcon
                className="address-city"
                inputName="address-city-input"
                height={70}
                type="text"
                placeholder="City"
                value={values.city}
                tabIndex={0}
                withBottomLine
                onChange={handleChange('city')}
                error={
                  (showValidationErrors || touched.city) && errors.city
                    ? errors.city
                    : undefined
                }
              />
            </InputContainer>
            {showDistrictField && (
              <div>
                <DropdownContainer>
                  <DropDown
                    options={
                      values.country === 'US' ? USDistricts : CanadianDistricts
                    }
                    placeholder={
                      values.country === 'US'
                        ? 'Type State'
                        : 'Type District/State'
                    }
                    styles={menuStyleFullWidth}
                    onChange={(option) => {
                      setFieldValue('district', (option as OptionType).value);
                    }}
                  />
                </DropdownContainer>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: '10px',
                  }}>
                  <Typography
                    text={
                      (showValidationErrors || touched.district) &&
                      showDistrictError &&
                      !values.district
                        ? 'District field is required'
                        : ''
                    }
                    fontSize="fz12"
                    fontColor={theme.colors.yellow}
                  />
                </div>
              </div>
            )}
            <InputContainer>
              <InputWithIcon
                className="address-zipcode"
                inputName="address-zipcode-input"
                height={70}
                type="text"
                placeholder={
                  values.country === 'UK' || values.country === 'GB'
                    ? 'Postcode'
                    : 'Zip Code'
                }
                value={values.zipCode}
                tabIndex={0}
                withBottomLine
                onChange={handleChange('zipCode')}
                error={
                  (showValidationErrors || touched.zipCode) && errors.zipCode
                    ? errors.zipCode
                    : undefined
                }
              />
            </InputContainer>
          </ColumnContainer>
        ) : (
          <></>
        )}
        {!addressSelected ? (
          <div style={{ marginTop: '10px' }}>
            <Button
              className="manual-address-button"
              label={
                <Typography
                  text="Add address manually"
                  fontColor={theme.colors.yellow}
                  fontWeight="regular"
                  letterSpacing="-0.03em"
                />
              }
              onClick={() => {
                setFieldValue('addressSearch', 'abc');
                setAddressSelected(true);
              }}
            />
          </div>
        ) : (
          <>
            {!noLocationFromIP && (
              <div style={{ marginTop: '10px' }}>
                <Button
                  label={
                    <Typography
                      text="Search for address"
                      fontColor={theme.colors.yellow}
                      fontWeight="regular"
                      letterSpacing="-0.03em"
                    />
                  }
                  onClick={() => {
                    setFieldValue('addressSearch', '');
                    setAddressSelected(false);
                  }}
                />
              </div>
            )}
          </>
        )}

        {values.country ? (
          <ColumnContainer>
            <Spacer height={20} />

            <InlineContainer>
              <LightText
                fontColor="white"
                text={'Country: ' + `${values.country}`}
              />
              <Button
                label={
                  <Typography
                    text="Change"
                    fontColor={theme.colors.yellow}
                    fontWeight="regular"
                    letterSpacing="-0.03em"
                  />
                }
                onClick={() => {
                  setFieldValue('country', null);
                  setFieldValue('addressSearch', '');
                  setAddressSelected(false);
                }}
              />
            </InlineContainer>
          </ColumnContainer>
        ) : (
          <ColumnContainer>
            <DropdownContainer>
              <DropDown
                className="address-search-country"
                options={CountryCodes}
                value={CountryCodes.find(
                  (data) => data.label === values.country,
                )}
                styles={menuStyleFullWidth}
                placeholder="Search country"
                onChange={(option) => {
                  const value = (option as OptionType).value;

                  setFieldValue('country', value);

                  if (value === 'US' || value === 'CA') {
                    setShowDistrictField(true);
                    if (!values.district) {
                      setDistrictError(true);
                    }
                  } else {
                    setShowDistrictField(false);
                  }
                }}
              />
            </DropdownContainer>
          </ColumnContainer>
        )}
        <Spacer height={20} />
        {type !== PaymentModalType.AddFunds ? (
          <FirstButton
            className="address-add-card-button"
            height={45}
            width={170}
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <ContentButton text="add" fontSize="fz16" fontWeight="bold" />
                &nbsp;
                <ContentButton
                  text="card"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={submitHandler}
          />
        ) : (
          <WhiteButton
            label="save Card"
            onClick={submitHandler}
            height={45}
            width={170}
            borderRadius={50}
            borderColor={theme.colors.white}
            bgColor={theme.colors.white}
            labelColor={theme.colors.black}
          />
        )}
      </>
    );
  }

  function getModalContent() {
    if (isAwaitingCardConfirmation) {
      return loading_screen();
    } else if (addCardComplete && addCardComplete.status === 'failed') {
      return card_failed_screen();
    } else if (
      addCardComplete &&
      addCardComplete.status === 'active' &&
      type === PaymentModalType.Quickflow
    ) {
      // Show Add funds modal
      onCardAdded(newCard);
      return;
    } else {
      return add_card_form();
    }
  }

  return (
    <CustomComponentContainer id="addCardModalContainer">
      <CloseButton onClick={handleClose} id="add-card-close-button">
        X
      </CloseButton>
      <BoldText
        text={
          type === PaymentModalType.AddFunds ? 'edit card' : 'add a new card'
        }
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />

      {getModalContent()}
    </CustomComponentContainer>
  );
};

export default AddCardModal;
