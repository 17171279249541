import React, { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import { useViewport } from 'use-viewport';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import Button from 'common/components/Button/Button';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
import Email from 'common/icons/Email.icon';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';

import Slider1 from '../assets/forgot-password.jpg';

import {
  ContactsLinkContainer,
  ContentButton,
  FirstButton,
  Form,
  HeadContainer,
  HeadContent,
  HeadingSection,
  Icon,
  Image,
  ImageContainer,
  ImageGradient,
  InputContainer,
  InputWithIcon,
  StrapLineTypography,
  StrapLineTypographyContainer,
  TextContent,
  TextContentForgot,
} from './styled/RemindPassword.styled';

import { FORGOT_PASSWORD } from '../graphql/Mutations.graphql';
import { FORGOT_PASSWORD_MUTATION, FORGOT_PASSWORD_VARIABLES } from '../types';
import { xs } from 'utils/constants';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email field is required'),
});

const RemindPassword = () => {
  const navigate = useNavigate();
  const viewport = useViewport();

  async function submitHandler() {
    try {
      const response = await forgotPassword();

      if (!response.errors) {
        if (response.data) {

          errors.email = 'Sent!';
          values.email = '';
        }
      }
    } catch (e: any) {}
  }

  const contactSupportHandler = () => {
    navigate('/support');
  };

  const initialValues = {
    email: '',
  };

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const [forgotPassword] = useMutation<
    FORGOT_PASSWORD_MUTATION,
    FORGOT_PASSWORD_VARIABLES
  >(FORGOT_PASSWORD, {
    variables: {
      email: values.email,
    },
  });

  useEffect(() => {
    const enterKeydown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') handleSubmit();
    };

    document.addEventListener('keydown', enterKeydown);

    return () => {
      document.removeEventListener('keydown', enterKeydown);
    };
  }, []);

  return (
    <PageContainer pageTitle='Forgot Password | SongBits'>
      <ImageContainer>
        <Image src={Slider1} />
        <ImageGradient banner={Slider1} />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <div style={{ display: 'flex' }}>
              <HeadContent
                text="i forgot it"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              &nbsp;
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
              />
            </div>
          </StrapLineTypographyContainer>

          <TextContentForgot
            text="Forgot Password"
            fontSize="fz18"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
          />

          <Form>
            <InputContainer>
              <Icon>
                <Email />
              </Icon>
              <InputWithIcon
                value={values.email}
                height={viewport.width < xs ? 63 : 70}
                type="text"
                onChange={handleChange('email')}
                label="Your Email Address"
                inputName={'email'}
                className={'forgot-password-email'}
                withBottomLine
                error={
                  Boolean(errors.email && touched.email)
                    ? errors.email
                    : undefined
                }
              />
            </InputContainer>

            <ContactsLinkContainer>
              <TextContent
                text="Still need help?"
                fontSize="fz12"
                fontColor={theme.colors.white}
              />
              <Button
                isTextBold={false}
                label={
                  <TextContent
                    text="Contact Support"
                    fontSize="fz12"
                    fontWeight="regular"
                    withUnderline
                  />
                }
                onClick={contactSupportHandler}
              />
            </ContactsLinkContainer>

            <FirstButton
              height={45}
              width={172}
              borderRadius={50}
              borderColor={theme.colors.white}
              className={'forgot-password-submit-button'}              
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton
                    text="remind"
                    fontSize="fz16"
                    fontWeight="bold"
                  />
                  &nbsp;
                  <ContentButton
                    text="me"
                    fontSize="fz16"
                    fontColor={theme.colors.yellow}
                    fontWeight="bold"
                  />
                </div>
              }
              onClick={handleSubmit}
            />
          </Form>
        </HeadingSection>
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(RemindPassword);
