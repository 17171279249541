import * as R from 'ramda';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';

import ToasterContainer from 'common/components/Toaster/Toaster';
import GlobalBanner from 'common/layout/GlobalBanner';
import web3Service from 'services/web3.service';
import { useStoreActions, useStoreState } from 'store/store';

import Routes from './Routes';
import { useAppDispatch, useAppSelector } from 'hooks/reduxToolkit';
import { setIsReloaded } from 'store-persist/reducers/modalSlice';
import { resetPersist } from 'store-persist/reducers/modalSlice';
import WebHelmet from 'common/components/GlobalHelmet/GlobalHelmet';

const INTERCOM_APP_ID = 'yhh9c702';


function App() {
  const dispatch = useAppDispatch();
  const { modalType, expiryTime } = useAppSelector((state) => state.modalSlice);

  const { isWalletConnected } = useStoreState((state) => state.wallet);
  const { setWalletData } = useStoreActions((state) => state.wallet);

  const metaWalletInitialize = async () => {
    const accounts = await web3Service.getAccounts();

    setWalletData({
      isWalletConnected: !R.isEmpty(accounts),
      walletAddress: accounts[0],
    });
  };

  useEffect(() => {
    if (modalType !== 'none') {
      dispatch(setIsReloaded(true));
    }
    if (expiryTime > 0 && expiryTime < new Date().getTime()) {
      window.localStorage.removeItem('purchaseId');
      dispatch(resetPersist());
    }
  }, []);

  useEffect(() => {
    if (window.ethereum) {
      metaWalletInitialize();

      (window as any).ethereum.on('chainChanged', async function () {
        const accounts = await web3Service.getAccounts();

        setWalletData({
          isWalletConnected,
          walletAddress: accounts[0],
        });
      });

      (window as any).ethereum.on('accountsChanged', async function () {
        const accounts = await web3Service.getAccounts();

        setWalletData({
          isWalletConnected,
          walletAddress: accounts[0],
        });
      });
    }
  }, [window.ethereum]);

  const showBanner = useStoreState(
    (state) => state.globalbanner.show_global_banner,
  );

  return (
    <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
      <WebHelmet title="SongBits" />
      <Router>
        <Routes />
        <ToasterContainer />
        {showBanner ? <GlobalBanner /> : ''}
      </Router>
    </IntercomProvider>
  );
}

export default App;
