import React, { FC } from 'react';
import styled from 'styled-components';

import DefaultButton from 'common/components/DefautButton/DefaultButton';

import PlayIcon from 'common/icons/Play.icon';
import StopIcon from 'common/icons/Stop.icon';

import { sm } from 'utils/constants';

interface ImagePlayerProps {
  imageSrc: string;
  isPlaying: boolean;
  onPlay: () => void;
  onPause: () => void;
}

const ImagePlayer: FC<ImagePlayerProps> = ({
  imageSrc,
  isPlaying,
  onPause,
  onPlay,
}) => {
  return (
    <Container image={imageSrc}>
      <PlayerControls>
        <DefaultButton hidden={isPlaying} onClick={onPlay}>
          <PlayIcon width={30} height={30} />
        </DefaultButton>
        <DefaultButton hidden={!isPlaying} onClick={onPause}>
          <StopIcon width={30} height={30} />
        </DefaultButton>
      </PlayerControls>
    </Container>
  );
};

const Container = styled.div<{ image: string }>`
  width: 100px;
  min-width: 100px;
  height: 100px;

  position: relative;

  border-radius: 10px;
  overflow: hidden;

  background-image: url(${(props) => props.image});
  background-repeat: no-repeat;
  background-size: cover;

  margin-right: 20px;

  @media (min-width: ${sm}px) {
    width: 150px;
    min-width: 150px;
    height: 150px;

    border-radius: 20px;
  }
`;

const PlayerControls = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`;

export default ImagePlayer;
