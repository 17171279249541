import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useViewport } from 'use-viewport';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import { isBrowserSupported } from 'utils/browser-check';

import Button from 'common/components/Button/Button';
import Select from 'react-select';
import { menuStyleFullWidth } from 'common/styles/DropdownStylingFullWidth';
import { useStoreActions } from 'store/store';
import restService from 'services/rest.service';
import MetamaskClient from 'services/web3.client';
import { USDistricts } from 'utils/us-districts';

interface ModalProps {
  onClose: () => void;
  onMetaMaskNotInstalled: () => void;
  onBrowserNotSupported: () => void;
  onWalletConnected: () => void;
  ConnectWallet: () => void;
  onNoAvailableAddresses: () => void;
  UDSCRestrictedState: (country: string) => void;
}

type OptionType = {
  value: string;
  label: string;
};

const USADetectionModal: FC<ModalProps> = ({
  onClose,
  onMetaMaskNotInstalled,
  onBrowserNotSupported,
  onWalletConnected,
  ConnectWallet,
  onNoAvailableAddresses,
  UDSCRestrictedState
}) => {
  const viewport = useViewport();

  const metamaskClient = new MetamaskClient(process.env.REACT_APP_METAMASK_SWITCH_CHAIN_NETWORK_ID);

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const VALIDATION_COUNTY_SCHEMA = Yup.object().shape({
    county: Yup.string().required('County field is required'),
  });

  const initialCountyValues = useMemo(
    () => ({
      county: '',
      county_name: ''
    }),
    [],
  );

  const {
    values: valuesCounty,
    setFieldValue: setCountyFieldValue,
    errors: errorsCounty,
    touched: touchedCounty,
  } = useFormik({
    initialValues: initialCountyValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_COUNTY_SCHEMA,
    onSubmit: stateSubmitHandler,
  });

  function stateSubmitHandler() {
    if (
      valuesCounty.county === 'NY' ||
      valuesCounty.county === 'HI' ||
      valuesCounty.county === 'MN' ||
      valuesCounty.county === 'NY'
    ) {

      // load state not available modal
      UDSCRestrictedState(valuesCounty.county_name)
      
    } else {

      restService
      .getCryptoAddresses()
      .then((res) => {

        // Check if incoming address exists
        let incomingAddress: any;
        res.forEach((address: any) => {
          if (address.status === 'incoming') {
            incomingAddress = address;
          }
        });

        if (incomingAddress) {
          if (incomingAddress.chain === 'ETH') {

            if (!(window as any).ethereum) {
              onMetaMaskNotInstalled();
            } else if (!isBrowserSupported()) {
              onBrowserNotSupported();
            } else {

              if ((window.ethereum as any)['isBraveWallet']) {
                onMetaMaskNotInstalled();                        
              }
              
              /*
              // Store purchase details in store
              setNumberOfBits(String(selectedBits));

              if (costOfSelected) {
                setCostOfSelected(costOfSelected);
              }
              */

              metamaskClient
                .connect(incomingAddress.nickname)
                .then(() => {
                  onWalletConnected();
                });
            }
          } else {
            // Switch chain
            ConnectWallet()
          }
        } else {
          onNoAvailableAddresses();
        }
      })
      .catch((error) => {
        setGlobalBanner({
          title: 'Failed to retrieve addresses:',
          text: error.message,
        });
      });

    }
  }


  return (
    <>
      <CloseButton onClick={onClose}>X</CloseButton>
      <BoldText
        text="crypto payment"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />
      <Spacer height={30} />
        <ModalText
          text="We are required to check which state US residents reside within when you choose to pay with USDC crypto."
          fontWeight="light"
          fontSize="fz18"
          fontColor={theme.colors.white}
        />
        <Spacer height={20} />
        <ModalText
          text="Please select your state below: "
          fontWeight="light"
          fontSize="fz18"
          fontColor={theme.colors.white}
        />
        <DropdownContainer>
          <DropDown
            options={USDistricts}
            placeholder="Type State"
            styles={menuStyleFullWidth}
            onChange={(option) => {
              setCountyFieldValue('county', (option as OptionType).value);
              setCountyFieldValue('county_name', (option as OptionType).label);
              console.table(valuesCounty);
            }}
          />
        </DropdownContainer>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            marginTop: '10px',
          }}>
          <Typography
            text={
              !valuesCounty.county && touchedCounty.county || errorsCounty.county && touchedCounty.county
                ? 'State field is required'
                : ''
            }
            fontSize="fz10"
            fontColor={theme.colors.yellow}
          />
        </div>

        <Spacer height={20} />
        <WhiteSubmitButton
          label={<Typography text="submit" fontWeight="bold" fontSize="fz16" />}
          onClick={() => {
            stateSubmitHandler();
          }}
          labelColor={theme.colors.white}
          borderColor={theme.colors.white}
          borderRadius={50}
          height={45}
          width={172}
        />
    </>
  );
};

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;


const ModalText = styled(Typography)`
  line-height: 24px;
`;

const WhiteSubmitButton = styled(Button)`
  padding: 0 30px;
  width: 172px;

  @media (min-width: 768px) {
    margin: initial;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
`;

export const DropDown = styled(Select)`
  width: 100%;
`;

export default USADetectionModal;
