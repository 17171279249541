import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import Edit from 'common/icons/Edit.icon';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';
import QuestionMark from 'common/icons/QuestionMark.icon';

import {
  ArtistApplicationWrapper,
  ArtistApplicationContent,
  AreaCode,
  ContentButton,
  CustomSpacer,
  CustomTextInput,
  FieldEdit,
  FieldLabel,
  FirstButton,
  Form,
  HeadContainer,
  HeadContent,
  HeadingSection,
  InfoButton,
  InputContainer,
  LabelSpacer,
  MediaContainer,
  MediaSection,
  PhoneInput,
  PhoneInputContainer,
  StrapLineTypographyContainer,
  TextContentHeading,
} from './styled/ArtistApplication.styled';

import {
  EMAIL_VALIDATION_PATTERN,
  //SPECIAL_CHARACTER_VALIDATION_PATTERN,
} from 'utils/validators';
import { useMutation } from '@apollo/client';
import {
  CREATE_ARTIST_APPLICATION,
  CREATE_ARTIST_APPLICATION_PUBLIC,
} from '../graphql/Mutations.graphql';
import {
  CREATE_ARTIST_APPLICATION_MUTATION,
  CREATE_ARTIST_APPLICATION_MUTATION_VARIABLES,
} from './types';
import { useNavigate } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'store/store';
import restService from 'services/rest.service';
import { getUserIdFromJWT } from 'utils/functions';
import { footerHeight, footerMobileHeight } from 'utils/constants';

const VALIDATION_SCHEMA = Yup.object().shape({
  userName: Yup.string()
    .min(6, 'Username must be at least 6 characters')
    .required('Username field is required'),
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email address is required'),
});

const ArtistApplication = () => {
  const navigate = useNavigate();
  const userId = getUserIdFromJWT();

  const isAuth = useStoreState((state) => state.authentication.isAuthenticated);

  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);
  setShowValidationErrors;

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const initialValues = useMemo(
    () => ({
      userName: '',
      managementName: '',
      phone: '',
      email: '',
      areaCode: '+1',
      socialMedia: {
        facebook: '',
        instagram: '',
        twitter: '',
        youtube: '',
        tiktok: '',
      },
      musicPlatforms: {
        spotify: '',
        applemusic: '',
        youtube: '',
        soundcloud: '',
      },
    }),
    [],
  );

  const {
    values,
    handleSubmit,
    handleChange,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const [applyNow] = useMutation<
    CREATE_ARTIST_APPLICATION_MUTATION,
    CREATE_ARTIST_APPLICATION_MUTATION_VARIABLES
  >(CREATE_ARTIST_APPLICATION, {
    variables: {
      artistName: values.userName,
      managementName: values.managementName,
      phoneNumber: values.phone,
      emailAddress: values.email,
      areaCode: values.areaCode,
      socialMediaPlatforms: JSON.stringify(values.socialMedia),
      musicPlatforms: JSON.stringify(values.musicPlatforms),
      intro: '',
      userId: userId,
    },
  });

  const [applyNowPublic] = useMutation<
    CREATE_ARTIST_APPLICATION_MUTATION,
    CREATE_ARTIST_APPLICATION_MUTATION_VARIABLES
  >(CREATE_ARTIST_APPLICATION_PUBLIC, {
    variables: {
      artistName: values.userName,
      managementName: values.managementName,
      phoneNumber: values.phone,
      emailAddress: values.email,
      areaCode: values.areaCode,
      socialMediaPlatforms: JSON.stringify(values.socialMedia),
      musicPlatforms: JSON.stringify(values.musicPlatforms),
      intro: '',
    },
  });

  useEffect(() => {
    if (isAuth) {
      getAccountSettings().then((res) => {
        setFieldValue('userName', res.username);
        setFieldValue('email', res.email);
      });
    }
  }, [isAuth]);

  async function getAccountSettings() {
    const transactions = await restService
      .getAccountSettings()
      .catch((error) => {
        setGlobalBanner({
          title: 'Failed to retrieve account settings: ',
          text: error.message,
        });
      });
    return transactions;
  }

  async function submitHandler() {
    try {
      let response;

      if (isAuth) {
        response = await applyNow();
      } else {
        response = await applyNowPublic();
      }

      if (!response.errors) {
        if (response.data) {
          navigate('/labels-artists/waitlist');
        }
      }
    } catch (error: any) { 
      if (error.message.includes('duplicate key')) {
        setFieldError('email', 'You have already submitted an application!');
        setFieldTouched('email', true, false);
      }
    }
  }

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}>
      <ArtistApplicationWrapper>
        <ArtistApplicationContent>
          <HeadContainer>
            <HeadingSection>
              <StrapLineTypographyContainer>
                <HeadContent
                  text="Lets do this"
                  fontSize="fz100"
                  fontWeight="bold"
                  fontColor={theme.colors.yellow}
                  letterSpacing="-0.03em"
                />
              </StrapLineTypographyContainer>
            </HeadingSection>
          </HeadContainer>
          <Form>
            <CustomSpacer />
            <TextContentHeading
              text="Artist Application"
              fontSize="fz24"
              fontWeight="bold"
              fontColor={theme.colors.white}
              letterSpacing="-0.03em"
            />
            <Spacer height={12} />
            <InputContainer>
              <CustomTextInput
                value={values.userName}
                height={75}
                type="text"
                onChange={handleChange('userName')}
                placeholder="Choose a Username"
                withBottomLine
                error={
                  (showValidationErrors || touched.userName) && errors.userName
                    ? errors.userName
                    : undefined
                }
              />
              <FieldEdit>
                <Edit width={25} height={20} />
              </FieldEdit>
            </InputContainer>
            <InputContainer>
              <CustomTextInput
                value={values.managementName}
                height={70}
                type="text"
                onChange={handleChange('managementName')}
                placeholder="Management name (optional)"
                withBottomLine
              />
            </InputContainer>
            <Spacer height={15} />
            <FieldLabel>
              <Typography
                fontSize="fz18"
                fontWeight="regular"
                text="Phone Number"
              />
            </FieldLabel>
            <PhoneInputContainer>
              <AreaCode
                value={values.areaCode}
                onChange={handleChange('areaCode')}
              />
              <PhoneInput
                value={values.phone}
                height={70}
                type="tel"
                onChange={handleChange('phone')}
                placeholder="Enter Phone Number"
                withBottomLine
              />
            </PhoneInputContainer>
            <InputContainer>
              <CustomTextInput
                value={values.email}
                height={70}
                type="email"
                onChange={handleChange('email')}
                placeholder="Enter Email Address"
                withBottomLine
                error={
                  (showValidationErrors || touched.email) && errors.email
                    ? errors.email
                    : undefined
                }
              />
              <FieldEdit>
                <Edit width={25} height={20} />
              </FieldEdit>
            </InputContainer>
            <LabelSpacer />
            <InputContainer>
              <MediaContainer>
                <MediaSection
                  type="socialMedia"
                  values={values.socialMedia || {}}
                  handleSetState={(field, value) => {
                    setFieldValue(field, value);
                  }}
                />
                <InfoButton>
                  <QuestionMark />
                </InfoButton>
              </MediaContainer>
              <LabelSpacer />
              <MediaContainer>
                <MediaSection
                  type="musicPlatforms"
                  values={values.musicPlatforms}
                  handleSetState={(field, value) => {
                    setFieldValue(field, value);
                  }}
                />
                <InfoButton>
                  <QuestionMark />
                </InfoButton>
              </MediaContainer>
            </InputContainer>
            <FirstButton
              onClick={handleSubmit}
              height={45}
              width={170}
              borderRadius={50}
              borderColor={theme.colors.white}
              label={
                <div style={{ display: 'flex' }}>
                  <ContentButton
                    text="apply"
                    fontSize="fz16"
                    fontWeight="bold"
                  />
                  &nbsp;
                  <ContentButton
                    text="now"
                    fontSize="fz16"
                    fontColor={theme.colors.yellow}
                    fontWeight="bold"
                  />
                </div>
              }
            />
            <Spacer height={50}></Spacer>
          </Form>
        </ArtistApplicationContent>
      </ArtistApplicationWrapper>
    </PageContainer>
  );
};

export default ArtistApplication;
