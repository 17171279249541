import { toast } from 'react-toastify';
import React, { ChangeEvent, useMemo, useState } from 'react';
import styled from 'styled-components';

import { notify } from 'common/components/Toaster/Toaster';
import ToasterModal from 'common/components/Toaster/components/ToasterModal';
import Cover from 'common/icons/Cover.icon';
//import Mark from 'common/icons/Mark.icon';
import { useStoreState } from 'store/store';
import theme from 'theme/theme';
import { getUserIdFromJWT } from 'utils/functions';

type ImagePickerProps = {
  image?: string;
  type: 'avatar' | 'cover';
  handleImage: (avatar: string, avatarId: string) => void;
};

const ImagePicker: React.FC<ImagePickerProps> = ({
  type = 'avatar',
  image,
  handleImage,
}) => {
  const { accessToken } = useStoreState((state) => state.authentication);

  const [toastId, setToastId] = useState<string>();

  const userId = getUserIdFromJWT();
  const isAvatar = useMemo(() => type === 'avatar', [type]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target && event.target.files) {
      const newImage = event.target.files[0];
      getAvatarId(newImage);
    }
  };

  function getAvatarId(avatar: File) {
    toast.dismiss(toastId);
    const newImage = URL.createObjectURL(avatar);

    const url = `${process.env.REACT_APP_FILES_URL}/fileUpload`;
    const data = new FormData();

    data.append('file', avatar);
    data.append('user_id', userId);
    data.append('context', 'avatar');

    fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: 'application/json',
      },
      body: data,
    })
      .then((response) => response.json())
      .then(({ data }) => {
        const newAvatarId = data.fileUpload.id;
        handleImage(newImage, newAvatarId);
      })
      .catch((error) => {
        if (error) {
          const toastId = notify({
            autoClose: false,
            customComponent: (
              <ToasterModal
                title="Something went wrong!"
                content="Please try again later"
              />
            ),
          });

          setToastId(toastId as string);
        }
      });
  }

  return (
    <>
      {image ? (
        <ImageContainer>
          <Image src={image} alt="image" />
          {/* TODO : Renaable when verified is working - isAvatar && (
            <MarkContainer>
              <Mark />
            </MarkContainer>
          )*/}
          <Label htmlFor="img" image={!!image} className="label">
            Replace Image
          </Label>
        </ImageContainer>
      ) : (
        <ImagePickerContainer>
          <PlusMark className="plus" htmlFor="img">
            +
          </PlusMark>
          {!isAvatar && (
            <CoverContainer>
              <Cover width={40} height={40} />
            </CoverContainer>
          )}
          {isAvatar ? (
            <Label htmlFor="img" className="label">
              Profile Image
            </Label>
          ) : (
            <Label htmlFor="img" className="label">
              Cover Image
            </Label>
          )}
        </ImagePickerContainer>
      )}
      <Input
        id="img"
        type="file"
        accept="image/jpeg,image/png"
        onChange={handleChange}
      />
    </>
  );
};

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: 126px;
  height: 126px;
  border-radius: 10px;

  @media (min-width: 576px) {
    width: 150px;
    height: 150px;
  }
`;

const Label = styled.label<{ image?: boolean }>`
  position: ${(props) => (props.image ? 'relative' : 'absolute')};
  width: 100%;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  color: ${theme.colors.white};
  font-size: ${theme.fontSizes.fz18 + 'px'};
`;

const ImagePickerContainer = styled.div`
  width: 126px;
  height: 126px;
  border: 1px solid ${theme.colors.yellow};
  border-radius: 10px;
  position: relative;
  cursor: pointer;

  &:hover .plus {
    color: ${theme.colors.black};
    background: ${theme.colors.yellow};
  }

  &:hover .label {
    background: none;
    color: ${theme.colors.yellow};
  }

  @media (min-width: 576px) {
    width: 150px;
    height: 150px;
  }
`;

const Input = styled.input`
  display: none;
`;

/*
const MarkContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  border: 3px solid ${theme.colors.white};
  background: ${theme.colors.blue};
  display: flex;
  align-items: center;
  justify-content: center;
`;
*/

const CoverContainer = styled.div`
  position: absolute;
  bottom: 2px;
  right: 4px;
`;

const PlusMark = styled.label`
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${theme.colors.yellow};
  font-size: 36px;
  font-weight: ${theme.fontWeight.bold};
  background: ${theme.colors.black};
`;

export default ImagePicker;
