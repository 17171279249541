import React, { useMemo, useEffect } from 'react';
import { useViewport } from 'use-viewport';

import PageContainer from 'common/layout/PageContainer';
//import Typography from 'common/components/Typography/Typography';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import QuickLinksMobile from 'common/components/QuickLinksMobile/QuickLinksMobile';
//import Spacer from 'common/components/Spacer/Spacer';

import PageLayout from 'common/components/PageLayout/PageLayout';



const CookiePolicy = () => {
  const viewport = useViewport();
  const quickLinks = useMemo(
    () => [
      {
        title: 'Terms & Conditions',
        link: '/legal/terms-conditions',
      },
      {
        title: 'Privacy Policy',
        link: '/legal/privacy-policy',
      },
      {
        title: 'Cookie Policy',
        link: '/legal/cookie-policy',
      },
      {
        title: 'Risk Factors',
        link: '/legal/risks',
      },
      /*{
        title: 'Community Guidelines',
        link: '/legal/community-guidelines',
      },*/
      {
        title: 'Company',
        link: '/company',
      },
    ],
    [],
  );

  const contentSection = (
    <>
    {viewport.width <= 767 ? (
      <QuickLinksMobile links={quickLinks} loading={false}></QuickLinksMobile>
    ) : (
      <></>
    )}
      <div id="cookie-declaration"></div>
    </>
  );


useEffect(() => {
  const script = document.createElement('script');

  script.src =
    'https://consent.cookiebot.com/2e8742dc-9cb9-4d64-875d-b8b9d8dcf2e2/cd.js';
  script.async = true;
  script.id = 'CookieDeclaration';

  const cookieDiv = document.getElementById('cookie-declaration');

  if (cookieDiv) {
    cookieDiv.appendChild(script);
  }

  return () => {
    const cookieDiv = document.getElementById('cookie-declaration');

    if (cookieDiv) {
      cookieDiv.removeChild(script);
    }
  };
}, []);

  return (
    <PageContainer  pageTitle='Cookie Policy | SongBits'>
      <PageLayout
        title="Cookie Policy"
        is2ColumnLayout
        sections={[
          {
            content: contentSection,
          },
          {
            content: (
              <>
                {viewport.width <= 767 ? (
                  <></>
                ) : (
                  <QuickLinks links={quickLinks}></QuickLinks>
                )}
              </>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};
export default CookiePolicy;
