import { useFormik } from 'formik';
import React, { useMemo, FC, useState, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useViewport } from 'use-viewport';
import Select from 'react-select';

import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import {
  AddIncomingCryptoAddressSchema,
  OptionType,
  PaymentModalType,
} from 'modules/payments/types';
import restService from 'services/rest.service';
import Button from 'common/components/Button/Button';
import theme from 'theme/theme';
import { v4 as uuidv4 } from 'uuid';
import { useStoreActions } from 'store/store';

import { menuStyle } from 'common/styles/DropdownStyling';
import {
  currencyOptions,
  cryptoChainOptions,
} from 'common/dropdown-values/crypto-options';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email field is required'),
});

interface ModalProps {
  onChange: () => void;
  onPaymentComplete?: () => void;
  type: PaymentModalType;
}

const AddIncomingCryptoModal: FC<ModalProps> = ({ onChange }) => {
  const viewport = useViewport();

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );

  const [addressCopied, setAddressCopied] = useState<boolean>(false);
  const [addressCreated, setAddressCreated] = useState<boolean>(false);
  const [addressdata, setAddressData] = useState<CryptoResults>({
    nickname: '',
    currency: '',
    chain: '',
    address: '',
    addressTag: undefined,
  });

  const initialValues = useMemo(
    () => ({
      addressNickname: '',
      cryptoChain: '',
      currency: 'USD',
      generatedAddress: '',
    }),
    [],
  );

  const { values, handleChange, setFieldValue, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  async function submitHandler() {
    const cryptoDetails: AddIncomingCryptoAddressSchema = {
      addressId: uuidv4(),
      nickname: values.addressNickname,
      currency: values.currency,
      chain: values.cryptoChain,
    };

    restService
      .addIncomingCryptoAddress(cryptoDetails)
      .then((res) => {
        setAddressCreated(true);
        setAddressData(res);
      })
      .catch((error) => {
        setGlobalBanner({
          title: 'Failed to add incoming address:',
          text: error.message,
        });
      });
  }

  function handleClose() {
    onChange();
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    setAddressCopied(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setAddressCopied(false);
    }, 3000);
  }, [addressCopied]);

  interface CryptoResults {
    nickname: string;
    currency: string;
    chain: string;
    address: string;
    addressTag?: string;
  }

  function crypto_result_screen(address: CryptoResults) {
    return (
      <div>
        <Typography fontColor="white" text="Address Successfully Created" />
        <LightText text={'Nickname: ' + address.nickname} />
        <LightText text={'Chain: ' + address.chain} />
        <LightText text={'Currency: ' + address.currency} />
        {address.addressTag ? (
          <>
            <LightText text={'Address Tag: ' + address.addressTag} />
          </>
        ) : (
          <></>
        )}
        <LightText text={'Address: ' + address.address} />
        <Spacer height={10} />
        <CustomButton
          label="Copy Address"
          onClick={() => {
            copyToClipboard(address.address);
          }}
        />
        {addressCopied && (
          <Typography
            text={'copied!'}
            fontWeight="light"
            fontColor="yellow"
            fontSize="fz14"
          />
        )}
      </div>
    );
  }

  function add_crypto_form() {
    return (
      <div>
        <InputContainer>
          <InputWithIcon
            value={values.addressNickname}
            height={70}
            type="text"
            onChange={handleChange('addressNickname')}
            placeholder="Address Nickname"
            withBottomLine
            error={
              Boolean(errors.addressNickname && touched.addressNickname)
                ? errors.addressNickname
                : undefined
            }
          />
        </InputContainer>

        <DropdownContainer>
          <Select
            styles={menuStyle}
            options={cryptoChainOptions}
            onChange={(option) => {
              setFieldValue('cryptoChain', (option as OptionType).value);
            }}
          />
        </DropdownContainer>

        <DropdownContainer>
          <Select
            styles={menuStyle}
            options={currencyOptions}
            onChange={(option) => {
              setFieldValue('currency', (option as OptionType).value);
            }}
          />
        </DropdownContainer>

        <Spacer height={50} />
        <FirstButton
          height={45}
          width={170}
          borderRadius={50}
          borderColor={theme.colors.white}
          label={
            <div style={{ display: 'flex' }}>
              <ContentButton text="add" fontSize="fz16" fontWeight="bold" />
              &nbsp;
              <ContentButton
                text="address"
                fontSize="fz16"
                fontColor={theme.colors.yellow}
                fontWeight="bold"
              />
            </div>
          }
          onClick={submitHandler}
        />
      </div>
    );
  }

  function getModalContent() {
    if (addressCreated) {
      // Return detail screen
      return crypto_result_screen(addressdata);
    } else {
      return add_crypto_form();
    }
  }

  return (
    <CustomComponentContainer>
      <CloseButton onClick={handleClose}>X</CloseButton>
      <BoldText
        text="add incoming crypto"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />

      {getModalContent()}
    </CustomComponentContainer>
  );
};

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

const DropdownContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 20px;
`;

const InputWithIcon = styled(TextInput)`
  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const FirstButton = styled(Button)`
  padding: 0 30px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: initial;
  }
`;

const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

const LightText = styled(Typography)`
  font-family: 'HKGrotesk-Light';
`;

const CustomButton = styled(Button)`
  border: 1px solid white;
  border-radius: 10px;
  padding: 5px 35px;
`;

export default AddIncomingCryptoModal;
