import { useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import restService from 'services/rest.service';
import Button from 'common/components/Button/Button';
//import Typography from 'common/components/Typography/Typography';
import WithRouteAnimation from 'common/hoc/WithRouteAnimation';
//import Email from 'common/icons/Email.icon';
import PageContainer from 'common/layout/PageContainer';
import theme from 'theme/theme';
import Slider1 from '../assets/confirm-email.jpg';

import {
  //ChangeEmailButton,
  //FirstButton,
  HeadContainer,
  HeadContent,
  HeadingSection,
  //Icon,
  Image,
  ImageContainer,
  ImageGradient,
  StrapLineTypography,
  StrapLineTypographyContainer,
  TextConfirm,
  TextContent,
  TextContentConfirm,
} from './styled/ConfirmEmail.styled';

import {
  CONFIRM_EMAIL,
  RESEND_VERIFICATION_EMAIL,
} from '../graphql/Mutations.graphql';
import {
  CONFIRM_EMAIL_DATA,
  CONFIRM_EMAIL_VARIABLES,
  RESEND_VERIFICATION_EMAIL_MUTATION,
  RESEND_VERIFICATION_EMAIL_VARIABLES,
} from '../types';
import store, { useStoreActions, useStoreState } from 'store/store';
import jwtDecode from 'jwt-decode';
import { useWebSocket } from 'react-use-websocket/dist/lib/use-websocket';
import { v4 as uuidv4 } from 'uuid';

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token, token_id } = useParams();
  const [searchParams] = useSearchParams();

  const emailAddress = useStoreState(
    (state) => state.authentication.emailAddress,
  );

  const isAuthenticated = useStoreState(
    (state) => state.authentication.isAuthenticated,
  );

  const logout = useStoreActions((state) => state.authentication.setLogout);

  const setGlobalBanner = useStoreActions(
    (actions) => actions.globalbanner.setGlobalBanner,
  );
  const setEmailAddress = useStoreActions(
    (actions) => actions.authentication.setEmailAddress,
  );

  const setAccessToken = useStoreActions(
    (actions) => actions.authentication.setAccessToken,
  );

  const setExpiryTime = useStoreActions(
    (actions) => actions.authentication.setExpiryTime,
  );

  const setEmailConfirm = useStoreActions(
    (actions) => actions.authentication.setEmailConfirm,
  );

  const setRedirectToGiveaway = useStoreActions(
    (actions) => actions.authentication.setRedirectToGiveaway,
  );

  const state = location.state ? (location.state as any) : '';
  let email = state ? state.email : '';

  if (emailAddress) {
    email = emailAddress;
  }

  if (!email && isAuthenticated) {
    getAccountSettings().then((res) => {
      email = res.email;
    });
  }

  const [isResentConfirmation, setIsResendConfirmation] =
    useState<boolean>(false);

  const [confirmEmail] = useMutation<
    CONFIRM_EMAIL_DATA,
    CONFIRM_EMAIL_VARIABLES
  >(CONFIRM_EMAIL);

  // Token from CreateAccount to resend conf email
  let stateToken = '';
  try {
    stateToken = (location.state as any).tokenId;
  } catch {}

  const [resendVerificationEmail] = useMutation<
    RESEND_VERIFICATION_EMAIL_MUTATION,
    RESEND_VERIFICATION_EMAIL_VARIABLES
  >(RESEND_VERIFICATION_EMAIL, {
    variables: {
      expiredTokenId: token_id || stateToken,
    },
  });

  useEffect(() => {
    if (token) {
      if (searchParams.get('email')) {
        email = searchParams.get('email');
        setEmailAddress(email);
      }
      verifyEmailAddress();
    }
  }, []);

  let user_id = '';
  try {
    user_id = (location.state as any).id;
  } catch {}

  const wsURL = process.env.REACT_APP_REST_URL?.replace('https', 'wss') + '/ws';
  const { sendJsonMessage } = useWebSocket(wsURL, {
    share: true,
    onOpen: () => {
      console.log('Websocket connection opened in ConfirmEmail.tsx');
      if (user_id) {
        console.log('There is a user ID');
        sendJsonMessage({
          message_id: uuidv4(),
          command: 'identify',
          params: {
            user_id: user_id,
          },
        });
      }
    },
    onClose: () =>
      console.log('Websocket connection closed in ConfirmEmail.tsx'),
    onMessage: (message) => {
      const messageData = JSON.parse(message.data);

      if (
        messageData.event &&
        messageData.event.name === 'email_confirmed' &&
        messageData.event.data.status
      ) {
        setGlobalBanner({
          title: 'Email Confirmed!',
          text: 'Please login with your credentials below',
        });

        store.persist.clear().then(() => {
          logout();
          navigate('/login');
        });
      }
    },
  });

  async function verifyEmailAddress() {
    if (token) {
      confirmEmail({ variables: { token: token } })
        .then((response) => {
          console.log('Response:');
          console.log(response);

          if (response.data) {
            const responseData = response.data.userConfirmationEmail.auth;
            setAccessToken(responseData.accessToken);
            setEmailConfirm(responseData.isEmailConfirmed);
            setRedirectToGiveaway(responseData.redirectToGiveaway);

            const decoded = jwtDecode(responseData.accessToken);
            setExpiryTime((decoded as any).exp);
            let isArtist = false;

            if ((decoded as any).role === 'app_artist') {
              isArtist = true;
            }

            (window as any).gtag('event', 'sign_up_confirmed', {});

            const redirect = searchParams.get('redirect') || ''; // Check for redirect url param
            const redirectAfterAuth = responseData.redirectAfterAuth; // Check for redirect param in response
            const navTo =
              redirectAfterAuth.length > 0 ? redirectAfterAuth : redirect;

            // console.log('Redirect: ', redirect);
            // console.log('Redirect After Auth: ', redirectAfterAuth);
            // console.log('NavTo: ', navTo);

            setGlobalBanner({
              title: 'Email Confirmed!',
              text: '',
            });

            if (navTo.length > 0) {
              navigate(navTo);
            } else {
              if (isArtist) {
                navigate('/artist/dashboard');
              } else {
                navigate('/library/hot-releases');
              }
            }
          }
        })
        .catch((error) => {
          if (
            error.message === 'Invalid token' ||
            error.message === 'Token expired'
          ) {
            setGlobalBanner({
              title: error.message + ': ',
              text: "Please click 'Resend Now'",
            });
          } else {
            setGlobalBanner({
              title: 'Error: ',
              text: 'Connecting to server',
            });
          }
        });
    }
  }

  /*const changeEmailRedirect = () => {
    navigate('/change-email', {
      state: {
        id: id,
        email: email,
      },
    });
  };*/

  async function getAccountSettings() {
    const account = await restService.getAccountSettings().catch((error) => {
      error;
    });
    return account;
  }

  async function resendConfirmationHandler() {
    if (!email) {
      const res = await getAccountSettings();
      email = res.email;
    }

    try {
      const response = await resendVerificationEmail();

      if (!response.errors) {
        if (response.data) {
          setIsResendConfirmation(true);
        }
      }
    } catch (e: any) {
      console.error(e);
    }
  }
  return (
    <PageContainer pageTitle="Confim Email | SongBits">
      <ImageContainer>
        <Image src={Slider1} />
        <ImageGradient banner={Slider1} />
      </ImageContainer>
      <HeadContainer>
        <HeadingSection>
          <StrapLineTypographyContainer>
            <HeadContent text="almost" fontSize="fz100" fontWeight="bold" />
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div
              style={{
                display: 'inline-block',
              }}>
              <HeadContent
                text="there"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              <StrapLineTypography
                text="!"
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
                fontColor={theme.colors.yellow}
              />
              <StrapLineTypography
                text="."
                fontSize="fz100"
                fontWeight="bold"
              />
            </div>
          </StrapLineTypographyContainer>

          <TextContentConfirm
            text="Confirm Email Address"
            fontSize="fz18"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.03em"
          />
          <div
            style={{
              position: 'relative',
              width: 350,
            }}>
            <TextConfirm
              text="Please confirm your email address."
              fontSize="fz18"
              fontColor={theme.colors.white}
            />
            <TextConfirm
              text="You will receive a email from us with a link to click. Check your email client. Remember to check your junk folder."
              fontSize="fz18"
              fontColor={theme.colors.white}
            />
          </div>

          {/*<FirstButton
            height={45}
            width={172}
            borderRadius={50}
            borderColor={theme.colors.white}
            label={
              <div style={{ display: 'flex' }}>
                <Typography text="open" fontSize="fz16" fontWeight="bold" />
                &nbsp;
                <Typography
                  text="mail"
                  fontSize="fz16"
                  fontColor={theme.colors.yellow}
                  fontWeight="bold"
                />
              </div>
            }
            onClick={() => {}}
          />*/}

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 5,
              marginTop: '40px',
              marginLeft: '5px',
            }}>
            <TextContent
              text="Still Not arrived?"
              fontSize="fz18"
              fontColor={theme.colors.white}
            />
            <Button
              isTextBold={false}
              label={
                <TextContent text="Resend Now." fontSize="fz18" withUnderline />
              }
              onClick={resendConfirmationHandler}
            />
            {isResentConfirmation ? (
              <TextContent text="Resent!" fontColor={theme.colors.yellow} />
            ) : null}
          </div>
          {/*isAuthenticated ? <>
            <ChangeEmailButton
              onClick={changeEmailRedirect}
              label={
                <TextContent
                  withCursorPointer
                  withUnderline
                  text="Want to change your email address?"
                />
              }
            />
          </> : <></>
            */}
        </HeadingSection>
      </HeadContainer>
    </PageContainer>
  );
};

export default WithRouteAnimation(ConfirmEmail);
