import React, { memo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStoreActions } from 'store/store';

import { useViewport } from 'use-viewport';

import PageContainer from 'common/layout/PageContainer';
import ArtistImage from '../Dashboard/components/ArtistImage';
import TextInputPlaceholder from 'common/components/Placeholder/TextInput';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import ImagePickerPlaceholder from 'common/components/Placeholder/ImagePicker';
import DropsPlaceholder from 'common/components/Placeholder/Drops';
import Drops from '../components/Drops/Drops';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';

import PageLayout from 'common/components/PageLayout/PageLayout';

import {
  ProfileImageContainer,
  AltContainer,
} from '../Dashboard/ArtistDashboard.styled';

import { DropsDataType } from 'modules/account/types';
import { extractDrops } from 'utils/drops';
import { WaitlistDashboardData } from 'services/types';
import { footerHeight, footerMobileHeight } from 'utils/constants';
import WaitlistStatSection from '../Dashboard/components/WaitlistStatSection';
import restService from 'services/rest.service';

const quickLinks = [
  {
    title: 'Artist Profile',
    link: '/artist/settings',
  },
  {
    title: 'Manage Releases',
    link: '/artist/releases',
  },
  {
    title: 'Insights & Analytics',
    link: '/artist/insights',
  },
  {
    title: 'Artist Support (A&R)',
    link: '/support',
  },
];

const WaitlistStats = () => {
  const navigate = useNavigate();
  const viewport = useViewport();

  const [dashoardData, setDashoardData] = useState<WaitlistDashboardData>();
  const [drops, setDrops] = useState<DropsDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const setMainFooterHidden = useStoreActions(
    (state) => state.style.setMainFooterHidden,
  );

  useEffect(() => {
    setMainFooterHidden(true);

    restService.getArtistWaitlistDashboard().then((artistDashboardData: any) => {
      if (!artistDashboardData) return;

      setDashoardData(artistDashboardData);

      if (artistDashboardData?.drops) {
        setDrops(extractDrops(artistDashboardData?.drops));
      }

      setLoading(false);
    });

    return () => {
      setMainFooterHidden(false);
    };
  }, []);

  const firstColumn = (
    <>
      <ProfileImageContainer>
        {loading ? (
          <ImagePickerPlaceholder />
        ) : (
          <ArtistImage
            text="Edit Artist Profile"
            image={dashoardData?.image}
            imageClickHandler={() => navigate('/artist/settings')}
            noImageClickHandler={() => navigate('/artist/settings')}
          />
        )}
      </ProfileImageContainer>
    </>
  );

  const secondColumn = (
    <>
      {loading ? (
        <>
          <TextInputPlaceholder />
          <TextInputPlaceholder />
          <TextInputPlaceholder />
          <TextInputPlaceholder />
          <TextInputPlaceholder />
        </>
      ) : (
        <>
          <WaitlistStatSection data={dashoardData} />
        </>
      )}
    </>
  );

  const thirdColumn = (
    <AltContainer>
      {loading ? (
        <DropsPlaceholder></DropsPlaceholder>
      ) : (
        <>
          <Drops drops={drops} isArtistArea />
        </>
      )}

      {loading && viewport.width >= 767 ? (
        <QuickLinksPlaceholder></QuickLinksPlaceholder>
      ) : (
        <>{viewport.width <= 767 ? <></> : <QuickLinks links={quickLinks} />}</>
      )}
    </AltContainer>
  );

  return (
    <PageContainer
      reduceFooter={`${footerHeight}px`}
      reduceFooterMobile={`${footerMobileHeight}px`}
      pageTitle={'Waitlist Stats | SongBits'}>
      <PageLayout
        loading={loading}
        title="waitlist stats"
        sections={[
          {
            content: firstColumn,
          },
          {
            content: secondColumn,
          },
          {
            content: thirdColumn,
          },
        ]}
      />
    </PageContainer>
  );
};

export default memo(WaitlistStats);
