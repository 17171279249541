import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import React, { useMemo, useState, FC, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useViewport } from 'use-viewport';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import TextInput from 'common/components/TextInput/TextInput';
import { notify } from 'common/components/Toaster/Toaster';
import Typography from 'common/components/Typography/Typography';
import ToasterModal from 'common/components/Toaster/components/ToasterModal';
import Email from 'common/icons/Email.icon';
import { USER_SEND_RESET_PASSWORD } from 'modules/account/graphql/Mutations.graphql';
import {
  USER_SEND_RESET_PASSWORD_MUTATION,
  USER_SEND_RESET_PASSWORD_VARIABLES,
} from 'modules/account/types';
import theme from 'theme/theme';
import { EMAIL_VALIDATION_PATTERN } from 'utils/validators';
import { escToClose } from 'utils/functions';
import Edit from 'common/icons/Edit.icon';

const VALIDATION_SCHEMA = Yup.object().shape({
  email: Yup.string()
    .matches(
      EMAIL_VALIDATION_PATTERN,
      'Invalid email address! Email will require verification.',
    )
    .required('Email field is required'),
});

interface ModalProps {
  email: string;
  onChange: () => void;
  onResetPassword: () => void;
}

const ResetPasswordModal: FC<ModalProps> = ({
  email,
  onChange,
  onResetPassword,
}) => {
  const viewport = useViewport();

  const navigate = useNavigate();
  const navigateToHandler = (route: string) => {
    navigate(route);
  };

  const [toastId, setToastId] = useState<string>();

  useEffect(() => {
    escToClose(onChange);
  }, []);

  const initialValues = useMemo(
    () => ({
      email: email,
    }),
    [],
  );

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const [userSendResetPassword, { loading }] = useMutation<
    USER_SEND_RESET_PASSWORD_MUTATION,
    USER_SEND_RESET_PASSWORD_VARIABLES
  >(USER_SEND_RESET_PASSWORD, {
    variables: {
      emailValue: values.email,
    },
  });

  async function submitHandler() {
    try {
      const response = await userSendResetPassword();
      if (!response.errors) {
        if (response.data) {
          toast.dismiss(toastId);
          onResetPassword();
          onChange();
        }
      }
    } catch (error: any) {
      const toastId = notify({
        autoClose: false,
        customComponent: (
          <ToasterModal
            title="Something gone wrong!"
            content="Please try again later"
          />
        ),
      });
      setToastId(toastId as string);
    }
  }

  return (
    <CustomComponentContainer>
      <CloseButton
        onClick={() => {
          onChange();
        }}>
        X
      </CloseButton>
      <BoldText
        text="reset password"
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
      />
      <Spacer height={20} />
      <RegularText
        fontSize={viewport.width >= 576 ? 'fz18' : 'fz14'}
        text={'A password reset link will be sent to the\nfollowing email'}
      />
      <InputContainer>
        <Icon>
          <Email />
        </Icon>
        <InputWithIcon
          height={70}
          type="email"
          withBottomLine
          value={values.email}
          placeholder="Your Email"
          onChange={handleChange('email')}
          error={
            Boolean(errors.email && touched.email) ? errors.email : undefined
          }
        />
        <EditEmail>
          <Edit width={20} height={20} />
        </EditEmail>
      </InputContainer>
      <Spacer height={30} />
      <ContactUsContainer>
        <Typography
          fontSize="fz12"
          text="Still need help?"
          fontColor={theme.colors.white}
        />
        <ContactUsButton
          label={
            <ContactUsText
              fontSize="fz12"
              text="Contact Support"
              fontColor={theme.colors.white}
            />
          }
          onClick={() => {
            navigateToHandler('/support');
          }}
        />
      </ContactUsContainer>
      <Spacer height={30} />
      <ButtonContainer
        borderColor={theme.colors.white}
        borderRadius={50}
        width={145}
        label={
          <SubmitButton>
            <Typography
              text="reset "
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.white}
            />
            <Typography
              text="password"
              fontSize="fz16"
              fontWeight="bold"
              fontColor={theme.colors.yellow}
            />
          </SubmitButton>
        }
        isLoading={loading}
        onClick={handleSubmit}
      />
      <Spacer height={viewport.width >= 576 ? 15 : 0} />
    </CustomComponentContainer>
  );
};

const SubmitButton = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-family: 'HKGrotesk-Black';
`;
const ContactUsContainer = styled.div`
  width: 260px;
  padding-left: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ContactUsText = styled(Typography)`
  text-decoration: underline;
`;

const ContactUsButton = styled(Button)`
  width: 100%;
  position: absolute;
  bottom: -3px;
`;

const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const InputContainer = styled.div`
  width: 75%;
  position: relative;
`;

const Icon = styled.div`
  position: absolute;
  bottom: 28px;
  left: 10px;
`;

const EditEmail = styled.div`
  position: absolute;
  right: 10%;
  top: 30%;
`;

const InputWithIcon = styled(TextInput)`
  & > input {
    padding-left: 35px;
    margin-top: -7px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;

  @media (min-width: 576px) {
    letter-spacing: -3px;
  }
`;

const RegularText = styled(Typography)`
  padding-right: 20px;
  letter-spacing: -0.03em;
  line-height: 24px;
  white-space: pre;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const ButtonContainer = styled(Button)`
  margin-left: 60px;
  width: 172px;
  height: 45px;

  @media (min-width: 576px) {
    margin-left: 0;
  }
`;

export default ResetPasswordModal;
