import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useViewport } from 'use-viewport';
import PageContainer from 'common/layout/PageContainer';
import PageLayout from 'common/components/PageLayout/PageLayout';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Spacer from 'common/components/Spacer/Spacer';
import QuickLinks from 'common/components/QuickLinks/QuickLinks';
import QuickLinksPlaceholder from 'common/components/Placeholder/QuickLinks';
import BottomLine from 'common/components/BottomLine/BottomLine';
import styled from 'styled-components';
import ImagePickerPlaceholder from 'common/components/Placeholder/ImagePicker';
import RoyaltyOverviewPlaceholder from 'common/components/Placeholder/Royalty/RoyaltyOverview';
import RoyaltyEarningsPlaceholder from 'common/components/Placeholder/Royalty/RoyaltyEarnings';
import Tip from 'common/components/Tip/Tip';
import restService from 'services/rest.service';
import { useStoreActions } from 'store/store';
import { roundNumberDown } from 'utils/functions';
import { MONTHS } from 'utils/constants';
import {
  SongLink,
  StatContainer,
  StatsSection,
  TipWrapper,
} from '../RoyaltyOverview';
import OpenYellow from 'common/icons/OpenYellow.icon';
import { ISongRoyaltyDetailData } from 'typings/account';

const SongRoyalty = () => {
  const viewport = useViewport();
  const { song_slug } = useParams();

  const [songData, setSongData] = useState<ISongRoyaltyDetailData>();

  const setBreadcrumbs = useStoreActions(
    (state) => state.location.setBreadcrumbs,
  );

  useEffect(() => {
    if (song_slug) {
      restService
        .getSongRoyaltyOverview(song_slug)
        .then((res) => {
          const data = res;
          data.total_purchase = parseFloat(res.total_purchase)
          data.total_purchase_avg = parseFloat(res.total_purchase_avg)
          data.no_purchases_avg = parseFloat(res.no_purchases_avg)
          setSongData(data);
        })
        .catch((error) => {
          error;
        });
    }
  }, [song_slug]);

  useEffect(() => {
    if (songData) {
      setBreadcrumbs([
        { value: 'Home', label: 'Home' },
        { value: 'account', label: 'My Account' },
        { value: 'royalty' || '', label: 'Royalty' },
        // { value: songData.songSlug || '', label: songData.songName },
      ]);
    }

    return () => {
      setBreadcrumbs([]);
    };
  }, [songData]);

  const quickLinks = useMemo(
    () => [
      {
        title: 'Account Settings',
        link: '/account/settings',
      },
      {
        title: 'My Wallet',
        link: '/account/wallet',
      },
      {
        title: 'My Collection',
        link: '/account/my-collection',
      },
      {
        title: 'Transaction History',
        link: '/account/transactions',
      },
      {
        title: 'Royalty Overview',
        link: '/account/royalty',
      },
      {
        title: 'Song Royalty Overview',
        link: '/account/royalty/songs',
      },
    ],
    [],
  );

  function formatDate(date: string) {
    const d = new Date(date);
    return d.getDate() + ' ' + MONTHS[d.getMonth()] + ' ' + d.getFullYear();
  }

  const songHeaderInfo = (
    <>
      {songData && (
        <Column
          gap={3}
          textAlign={'left'}
          style={{ width: '334px' }}>
          <Typography
            text={`${songData.song_name}`}
            fontSize="fz36"
            fontWeight="bold"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.05em"
            lineHeight="34px"
          />
          <Typography
            text={songData.artist_name}
            fontSize="fz24"
            fontWeight="light"
            letterSpacing="-0.02em"
            lineHeight="34px"
          />

          <Spacer height={5} />

          <Typography
            text={`Release Date: ${formatDate(songData.release_at)}`}
            fontWeight="bold"
            fontSize="fz18"
            lineHeight="34px"
          />

          <Spacer height={20} />
          <BottomLine />
          <Spacer height={5} />

          <StatsSection>
            <StatContainer>
              <Typography
                text={`${Number(songData.total_number_bits).toLocaleString()}`}
                fontSize="fz60"
                fontWeight="bold"
                lineHeight="78px"
                letterSpacing="-0.03em"
                fontColor={theme.colors.yellow}
              />
              <Typography
                text={`total song bits available`}
                fontSize="fz24"
                fontWeight="light"
                lineHeight="25px"
                fontColor={theme.colors.white}
              />
              <Typography
                text={`representing ${songData.song_length_minutes}m ${songData.song_length_seconds}s running time`}
                fontSize="fz14"
                fontWeight="bold"
                lineHeight="35px"
                fontColor={theme.colors.white}
              />
              <TipWrapper>
                <Tip
                  text={
                    <div>
                      <Typography
                        text={
                          'Total number of SongBits that were available for purchase'
                        }
                        fontSize={'fz14'}
                        lineHeight={'18px'}
                      />
                    </div>
                  }
                  width={210}
                  marginBottom={viewport.width < 576 ? 5 : 2}
                  position={viewport.width < 576 ? 'left' : 'left'}
                  left={viewport.width < 576 ? -210 : -200}
                />
              </TipWrapper>
            </StatContainer>
            <BottomLine />
            <StatContainer>
              <Typography
                text={Number(songData.owned_bits).toLocaleString()}
                fontSize="fz60"
                fontWeight="bold"
                lineHeight="78px"
                letterSpacing="-0.03em"
                fontColor={theme.colors.yellow}
              />
              <Typography
                text={`your song bits for this song`}
                fontSize="fz24"
                fontWeight="light"
                lineHeight="25px"
                fontColor={theme.colors.white}
              />
              <Typography
                text={`+${songData.owned_bits_percent}% from last month`}
                fontSize="fz14"
                fontWeight="bold"
                lineHeight="35px"
                fontColor={theme.colors.white}
              />
              <TipWrapper>
                <Tip
                  text={
                    <div>
                      <Typography
                        text={
                          'Number of bits you have bought of the song'
                        }
                        fontSize={'fz14'}
                        lineHeight={'18px'}
                      />
                    </div>
                  }
                  width={210}
                  marginBottom={viewport.width < 576 ? 5 : 2}
                  position={viewport.width < 576 ? 'left' : 'left'}
                  left={viewport.width < 576 ? -210 : -200}
                />
              </TipWrapper>
            </StatContainer>
            <BottomLine />
            <StatContainer>
              <Typography
                text={`$${songData.total_purchase.toLocaleString()}`}
                fontSize="fz60"
                fontWeight="bold"
                lineHeight="78px"
                letterSpacing="-0.03em"
                fontColor={theme.colors.yellow}
              />
              <Typography
                text={`total paid for this songs' bits`}
                fontSize="fz24"
                fontWeight="light"
                lineHeight="25px"
                fontColor={theme.colors.white}
              />
              <Typography
                text={`average of $${songData.total_purchase_avg.toFixed(
                  2,
                )} per bit`}
                fontSize="fz14"
                fontWeight="bold"
                lineHeight="35px"
                fontColor={theme.colors.white}
              />
              <SongLink to={`/account/wallet`}>
                <OpenYellow width={20} height={20} />
                <Typography
                  text={`view wallet`}
                  fontSize="fz18"
                  fontWeight="regular500"
                  lineHeight="24px"
                  letterSpacing="-0.03em"
                  fontColor={theme.colors.yellow}
                />
              </SongLink>
              <TipWrapper>
                <Tip
                  text={
                    <div>
                      <Typography
                        text={
                          'Total you paid for the SongBits'
                        }
                        fontSize={'fz14'}
                        lineHeight={'18px'}
                      />
                    </div>
                  }
                  width={210}
                  marginBottom={viewport.width < 576 ? 5 : 2}
                  position={viewport.width < 576 ? 'left' : 'left'}
                  left={viewport.width < 576 ? -210 : -200}
                />
              </TipWrapper>
              <Spacer height={10} />
            </StatContainer>
            <BottomLine />
            <StatContainer>
              <Typography
                text={`$${roundNumberDown(songData.lifetime_royalty.toString())}`}
                fontSize="fz60"
                fontWeight="bold"
                lineHeight="78px"
                letterSpacing="-0.03em"
                fontColor={theme.colors.yellow}
              />
              <Typography
                text={`lifetime royalty earnings`}
                fontSize="fz24"
                fontWeight="light"
                lineHeight="25px"
                fontColor={theme.colors.white}
              />
              <Spacer height={10} />
              <SongLink to={`/account/royalty/history`}>
                <OpenYellow width={20} height={20} />
                <Typography
                  text={`view royalty history`}
                  fontSize="fz18"
                  fontWeight="regular500"
                  lineHeight="24px"
                  letterSpacing="-0.03em"
                  fontColor={theme.colors.yellow}
                />
              </SongLink>
              <TipWrapper>
                <Tip
                  text={
                    <div>
                      <Typography
                        text={
                          'Royalty earned for the song to date.'
                        }
                        fontSize={'fz14'}
                        lineHeight={'18px'}
                      />
                    </div>
                  }
                  width={210}
                  marginBottom={viewport.width < 576 ? 5 : 2}
                  position={viewport.width < 576 ? 'left' : 'left'}
                  left={viewport.width < 576 ? -210 : -200}
                />
              </TipWrapper>
              <Spacer height={10} />
            </StatContainer>
            <BottomLine />
            <StatContainer>
              <Typography
                text={`$${roundNumberDown(songData.royalty_period_total.toString())}`}
                fontSize="fz60"
                fontWeight="bold"
                lineHeight="78px"
                letterSpacing="-0.03em"
                fontColor={theme.colors.yellow}
              />
              <Typography
                text={`royalty earned in ${songData.royalty_period_month_reported}`}
                fontSize="fz24"
                fontWeight="light"
                lineHeight="25px"
                fontColor={theme.colors.white}
              />
              <Typography
                text={`based on ${songData.royalty_period_month} royalties`}
                fontSize="fz14"
                fontWeight="bold"
                lineHeight="35px"
                fontColor={theme.colors.white}
              />
              <Spacer height={10} />
              <SongLink to={`/account/royalty/history`}>
                <OpenYellow width={20} height={20} />
                <Typography
                  text={`view royalty history`}
                  fontSize="fz18"
                  fontWeight="regular500"
                  lineHeight="24px"
                  letterSpacing="-0.03em"
                  fontColor={theme.colors.yellow}
                />
              </SongLink>
              <TipWrapper>
                <Tip
                  text={
                    <div>
                      <Typography
                        text={
                          'Royalty earned based on royalty latest reported period.'
                        }
                        fontSize={'fz14'}
                        lineHeight={'18px'}
                      />
                    </div>
                  }
                  width={210}
                  marginBottom={viewport.width < 576 ? 5 : 2}
                  position={viewport.width < 576 ? 'left' : 'left'}
                  left={viewport.width < 576 ? -210 : -200}
                />
              </TipWrapper>
              <Spacer height={10} />
            </StatContainer>
            <BottomLine />
            <StatContainer>
              <Typography
                text={`${songData.no_purchases}`}
                fontSize="fz60"
                fontWeight="bold"
                lineHeight="78px"
                letterSpacing="-0.03em"
                fontColor={theme.colors.yellow}
              />
              <Typography
                text={`purchases of this songs' bits`}
                fontSize="fz24"
                fontWeight="light"
                lineHeight="25px"
                fontColor={theme.colors.white}
              />
              <Typography
                text={`average purchase price $${songData.no_purchases_avg.toFixed(
                  2,
                )}`}
                fontSize="fz14"
                fontWeight="bold"
                lineHeight="35px"
                fontColor={theme.colors.white}
              />
              <Spacer height={10} />
              <SongLink to={`/account/transactions`}>
                <OpenYellow width={20} height={20} />
                <Typography
                  text={`view transaction history`}
                  fontSize="fz18"
                  fontWeight="regular500"
                  lineHeight="24px"
                  letterSpacing="-0.03em"
                  fontColor={theme.colors.yellow}
                />
              </SongLink>
              <TipWrapper>
                <Tip
                  text={
                    <div>
                      <Typography
                        text={
                          'The number of times you have purchased bits for the song.'
                        }
                        fontSize={'fz14'}
                        lineHeight={'18px'}
                      />
                    </div>
                  }
                  width={210}
                  marginBottom={viewport.width < 576 ? 5 : 2}
                  position={viewport.width < 576 ? 'left' : 'left'}
                  left={viewport.width < 576 ? -210 : -200}
                />
              </TipWrapper>
              <Spacer height={10} />
            </StatContainer>
          </StatsSection>
        </Column>
      )}
    </>
  );

  const firstSection = (
    <Column>
      {!songData && viewport.width < 576 ? (
        <RoyaltyOverviewPlaceholder />
      ) : (
        <>
          <SongImageContainer>
            {songData ? (
              <SongImage src={songData.song_image} />
            ) : (
              <ImagePickerPlaceholder
                width={150}
                height={150}
                withTextPlaceholder={false}
              />
            )}
          </SongImageContainer>
          {viewport.width < 576 && songHeaderInfo}
        </>
      )}
    </Column>
  );

  const secondSection = (
    <>
      {!songData ? (
        <>
          {viewport.width >= 576 && (
            <RoyaltyOverviewPlaceholder withImagePlaceholder={false} />
          )}
          <RoyaltyEarningsPlaceholder />
        </>
      ) : (
        <Column style={{ width: 'inherit', justifyContent: 'start' }}>
          {viewport.width > 576 && songHeaderInfo}
        </Column>
      )}
    </>
  );

  return (
    <PageContainer pageTitle={'Song Royalty | SongBits'}>
      <PageLayout
        padding={
          viewport.width >= 576 ? '100px 20px 0 20px' : '100px 20px 0 20px'
        }
        // loading={loading}
        title="song royalty detail"
        secondColumnWidth="334px"
        secondColumnMargin="25px"
        sections={[
          {
            content: firstSection,
          },
          {
            content: secondSection,
          },
          {
            content: (
              <div
                style={{
                  display: 'flex',
                  gap: '25px',
                  flexDirection: 'column',
                }}>
                {!songData && viewport.width >= 767 ? (
                  <QuickLinksPlaceholder></QuickLinksPlaceholder>
                ) : (
                  <>
                    {viewport.width <= 767 ? (
                      <></>
                    ) : (
                      <QuickLinks links={quickLinks} />
                    )}
                  </>
                )}
              </div>
            ),
          },
        ]}
      />
    </PageContainer>
  );
};

const Column = styled.div<{
  textAlign?: string;
  paddingLeft?: number;
  gap?: number;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${(props) => (props.gap ? props.gap : 5)}px;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  ${(props) =>
    props.paddingLeft ? `padding-left: ${props.paddingLeft}px` : ''};
`;

const SongImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (min-width: 576px) {
    justify-content: unset;
    margin-top: unset;
  }
`;

const SongImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 20px;
`;

export default SongRoyalty;
