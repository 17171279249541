import { gql } from '@apollo/client';

export const GET_ALL_USERS = gql`
  query getAllUsers {
    allUsers {
      totalCount
    }
  }
`;

export const GET_SONG_BY_SLUG = gql`
  query getSongBySlug($slug: String!) {
    songBySlug(slug: $slug) {
      id
      slug
      audioLengthMs
      editionsCount
      explicitContent
      title
      status
      artistName
      actualBitPrice
      remainingBits
      audioLengthMs
      actualBitPrice
      soldBitsInverted
      soldBits
      spotify
      releaseAt
      songType
      apple
      artistName
      artistProfileByArtistId {
        userId
        artistName
        slug
        intro
        userByUserId {
          artistIntrestsByArtist {
            edges {
              node {
                id
                email
              }
            }
          }
        }
      }
      fileByPreviewFile {
        url
        id
      }
      fileByCoverId {
        url
      }
      fileByBackgroundId {
        url
      }
      description
    }
  }
`;

export const GET_COUNTRY_CODE = gql`
  query CountryByISOCode($isoCode: String!) {
    allCountries(condition: { isoCode: $isoCode }) {
      edges {
        node {
          id
        }
      }
    }
  }
`;
